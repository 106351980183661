import { useRecoilState, useSetRecoilState } from 'recoil';

import { DBTScanType, FindingShape } from 'src/interfaces';
import controlState from 'src/states/control';
import { taskState } from 'src/states/task';

const useSetShowDBTScanType = (): ((newScanType: DBTScanType) => void) => {
  const setSelectedDBTScanType = useSetRecoilState(
    taskState.selectedDBTScanType
  );
  const [currentControl, setCurrentControl] = useRecoilState(
    controlState.current
  );

  const setShowDBTScanTypeImages = (newScanType: DBTScanType) => {
    if (newScanType === '3D' && currentControl === FindingShape.POLYGON) {
      setCurrentControl(FindingShape.MULTI_FRAME_POLYGON);
    }
    if (
      newScanType !== '3D' &&
      currentControl === FindingShape.MULTI_FRAME_POLYGON
    ) {
      setCurrentControl(FindingShape.POLYGON);
    }
    setSelectedDBTScanType(newScanType);
  };
  return setShowDBTScanTypeImages;
};

export default useSetShowDBTScanType;
