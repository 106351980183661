import { Children, ReactNode, useEffect, useMemo, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import HorizontalFourViewerIcon from 'src/components/icons/HorizontalFourViewerIcon';
import MatrixFourViewerIcon from 'src/components/icons/MatrixFourViewerIcon';
import { CPCCaseChip } from 'src/components/viewers/cpc/CPCCaseChip';
import { OpenOtherCPCCaseButton } from 'src/components/viewers/cpc/OpenOtherCPCCaseButton';
import useSetFullscreenIndex from 'src/hooks/tasks/useSetFullscreenIndex';
import useSetShowDBTScanType from 'src/hooks/tasks/useSetShowDBTScanType';
import useUpdateFullscreenIndex from 'src/hooks/tasks/useUpdateFullscreenIndex';
import {
  ViewPresetIconColor,
  ViewPresetIconProps,
  ViewPresetIconStatus,
} from 'src/interfaces';
import { DBTScanType, ViewPresetType } from 'src/interfaces/job';
import imageState from 'src/states/image';
import { projectState } from 'src/states/project';
import { taskState } from 'src/states/task';
import { thisCPCWindow } from 'src/utils/cpc';
import FindingUtils from 'src/utils/finding';
import localStore from 'src/utils/localStore';

export interface MultiViewProps {
  children: ReactNode;
  className?: string;
}

type ViewPresetButtonsType = {
  name: ViewPresetType;
  Icon: ({ status }: ViewPresetIconProps) => JSX.Element;
  isDisabled: boolean;
  gridStyle: string;
};

const style4Horizontal = `
  grid-template-columns: repeat(auto-fit, minmax(25%, auto));
  grid-template-rows: repeat(auto-fit, minmax(100%, auto));
`;

const style4Matrix = `
  grid-template-columns: repeat(auto-fit, minmax(50%, auto));
  grid-template-rows: repeat(auto-fit, minmax(50%, auto));
`;

const viewPresetButtons: ViewPresetButtonsType[] = [
  {
    name: '4-horizontal-viewer',
    Icon: HorizontalFourViewerIcon,
    isDisabled: false,
    gridStyle: style4Horizontal,
  },
  {
    name: '4-matrix-viewer',
    Icon: MatrixFourViewerIcon,
    isDisabled: false,
    gridStyle: style4Matrix,
  },
];

export function MultiView({
  children,
  className,
}: MultiViewProps): JSX.Element {
  const isCPCProject = useRecoilValue(projectState.isCPC);
  const isDBTProject = useRecoilValue(projectState.isDBT);
  const showDBTScanType = useRecoilValue(taskState.selectedDBTScanType);
  const setShowDBTScanType = useSetShowDBTScanType();
  const fullscreenIndex = useRecoilValue(taskState.fullscreenIndex);
  const setFullscreenIndex = useSetFullscreenIndex();
  const setFindingIndex = useSetRecoilState(taskState.findingIndex);
  const hasFFDMViews = useRecoilValue(imageState.hasFFDMViews);
  const hasDBTS2DViews = useRecoilValue(imageState.hasDBTS2DViews);
  const findings = useRecoilValue(taskState.findings);
  const assets = useRecoilValue(taskState.assets);
  const unmatchedFindingLabels =
    FindingUtils.getUnmatchedFindingLabels(findings);

  const unmatchedFindingLabelAsset = assets.find(
    asset => asset.name === unmatchedFindingLabels[0]?.label?.name
  );

  const unmatchedFindingLabelDisplayName =
    unmatchedFindingLabelAsset?.text || unmatchedFindingLabels[0]?.label?.name;

  useUpdateFullscreenIndex();

  const [activeViewPreset, setActiveViewPreset] = useState<
    ViewPresetButtonsType | undefined
  >(viewPresetButtons[0]);

  const cachedActiveViewPreset = useMemo(() => {
    const cachedName = localStore.getViewerPresetName();

    return !!cachedName
      ? viewPresetButtons.find(({ name }) => name === cachedName) ||
          activeViewPreset
      : activeViewPreset;
  }, [activeViewPreset]);

  const isFullscreenActivated = fullscreenIndex !== undefined;

  const getDisplayable = (index: number, fullscreenIndex?: number) => {
    if (!isFullscreenActivated) {
      return true;
    }
    return fullscreenIndex === index;
  };

  const handleClickViewPreset = (clickedViewPreset: ViewPresetButtonsType) => {
    setActiveViewPreset(clickedViewPreset);
    localStore.setViewerPresetName(clickedViewPreset.name);
    setFullscreenIndex(undefined);
  };

  const viewPresetIconStatus = (
    viewPreset: ViewPresetButtonsType
  ): ViewPresetIconStatus | undefined => {
    if (viewPreset.isDisabled) {
      return 'DISABLED';
    }
    const isIconActive =
      !isFullscreenActivated &&
      cachedActiveViewPreset?.name === viewPreset.name;
    if (isIconActive) {
      return 'ACTIVE';
    }
  };

  const handleChangeScanType = (scanType: DBTScanType) => {
    setFindingIndex(undefined);
    setShowDBTScanType(scanType);
  };

  useEffect(() => {
    window.name = isCPCProject ? thisCPCWindow.name : '';
  }, [isCPCProject]);

  const viewerTestId = useMemo(() => {
    if (!isDBTProject) {
      return 'mmg-viewer';
    }
    return `dbt-viewer-${showDBTScanType.toLowerCase()}`;
  }, [isDBTProject, showDBTScanType]);

  const isScanTypeSelected = (scanType: DBTScanType) =>
    showDBTScanType === scanType ? 'contained' : 'outlined';

  return (
    <Container>
      <ViewSettingsContainer>
        {isCPCProject && <CPCCaseChip />}
        {isDBTProject && (
          <ToggleDBTScanType direction="row" alignItems="center">
            <Button
              variant={isScanTypeSelected('3D')}
              size="small"
              onClick={() => handleChangeScanType('3D')}
              data-testid="showDBT3D-btn"
            >
              View DBT (3D)
            </Button>
            {hasFFDMViews && (
              <Button
                variant={isScanTypeSelected('FFDM')}
                size="small"
                onClick={() => handleChangeScanType('FFDM')}
                disabled={!hasFFDMViews}
                data-testid="showDBTFFDM-btn"
              >
                View FFDM (2D)
              </Button>
            )}
            {hasDBTS2DViews && (
              <Button
                variant={isScanTypeSelected('S2D')}
                size="small"
                onClick={() => handleChangeScanType('S2D')}
                disabled={!hasDBTS2DViews}
                data-testid="showDBTS2D-btn"
              >
                View S2D
              </Button>
            )}
            {unmatchedFindingLabels.length > 0 && (
              <NoMaxWidthTooltip
                title={`Group (${unmatchedFindingLabels[0]?.group}) - Label (${unmatchedFindingLabelDisplayName})`}
                placement="top"
                arrow
              >
                <Chip
                  icon={<CrisisAlertIcon />}
                  label="Unmatched FindingLabel values"
                  variant="outlined"
                  size="small"
                  color="warning"
                  onClick={() => {}}
                />
              </NoMaxWidthTooltip>
            )}
          </ToggleDBTScanType>
        )}

        <LayoutPresetHolder>
          <StyledViewPresetsLabel>View Presets</StyledViewPresetsLabel>
          <LayoutPresetButtons direction="row" gap="8px">
            {viewPresetButtons.map(view => (
              <StyledIconButton
                key={view.name}
                disabled={view.isDisabled}
                onClick={() => handleClickViewPreset(view)}
                disableRipple
              >
                <StyledIconContainer>
                  <view.Icon status={viewPresetIconStatus(view)} />
                </StyledIconContainer>
              </StyledIconButton>
            ))}
          </LayoutPresetButtons>
          {isCPCProject && <OpenOtherCPCCaseButton />}
        </LayoutPresetHolder>
      </ViewSettingsContainer>
      <MultiViewPanels
        className={className}
        $isFullscreen={isFullscreenActivated}
        $gridStyle={cachedActiveViewPreset?.gridStyle}
        data-testid={viewerTestId}
      >
        {Children.map(children, (child, i) => {
          return (
            <ViewPanel
              key={'quarter-' + i}
              $isFullscreen={isFullscreenActivated}
              $isDisplayable={getDisplayable(i, fullscreenIndex)}
            >
              {child}
            </ViewPanel>
          );
        })}
      </MultiViewPanels>
    </Container>
  );
}

const Container = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const MultiViewPanels = styled('div')<{
  $gridStyle: string | undefined;
  $isFullscreen: boolean;
}>(
  ({ $gridStyle, $isFullscreen }) => `
  position: relative;
  background-color: #1c2331; // line color
  display: ${$isFullscreen ? 'block' : 'grid'};
  ${$gridStyle}
`
);

const ViewPanel = styled('div')<{
  $isDisplayable: boolean;
  $isFullscreen: boolean;
}>(
  ({ $isDisplayable, $isFullscreen }) => `
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  border: 1px solid #1c2331;

  ${
    $isFullscreen &&
    `
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${$isDisplayable ? '1' : '-1'};
  `
  }
`
);

const ViewSettingsContainer = styled('div')`
  display: flex;
  place-content: end;
  padding: 0px 8px;
  align-items: center;
`;

const ToggleDBTScanType = styled(Stack)`
  display: flex;
  padding: 0px 8px;
  flex-grow: 1;
  gap: 8px;
`;

const LayoutPresetHolder = styled('div')`
  display: flex;
  place-content: end;
  align-items: center;
  flex-grow: 1;
`;

const StyledViewPresetsLabel = styled(Typography)`
  color: #8993ae;
`;

const LayoutPresetButtons = styled(Stack)`
  padding: 10px 8px 10px 10px;
`;

const StyledIconButton = styled(IconButton)`
  border-radius: 0;
  padding: 0;
`;

const StyledIconContainer = styled('div')`
  display: flex;
  width: 32px;
`;

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

export { ViewPresetIconColor };
