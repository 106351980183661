import { useRecoilValueLoadable } from 'recoil';

import FirstPage from '@mui/icons-material/FirstPage';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import useMoveToFirstJob from 'src/hooks/currentJobId/useMoveToFirstJob';
import { jobState } from 'src/states/job';

interface Props {
  editIndexMode: boolean;
}

const GoToFirstJobButton = ({ editIndexMode }: Props): JSX.Element => {
  const moveToFirstJob = useMoveToFirstJob();

  const canGoPrevJobLoadable = useRecoilValueLoadable(jobState.canGoPrev);
  const canGoPrevJob =
    canGoPrevJobLoadable.state === 'hasValue' && canGoPrevJobLoadable.contents;

  return (
    <Tooltip title="First Job">
      <span>
        <IconButton
          disabled={!canGoPrevJob || editIndexMode}
          onClick={moveToFirstJob}
          size="large"
          data-testid="btn-first-job"
        >
          <FirstPage />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default GoToFirstJobButton;
