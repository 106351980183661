import { useCallback } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Label } from 'src/interfaces';
import { jobState } from 'src/states/job';
import { taskState } from 'src/states/task';
import AssetUtils from 'src/utils/asset';
import FindingUtils from 'src/utils/finding';

import useConfirmFinding from './useConfirmFinding';

export type SetLabelFunction = (label: Label, index: number) => void;

const useSetFindingLabel = (): SetLabelFunction => {
  const confirmFinding = useConfirmFinding();

  const currentFindingAssets = useRecoilValue(taskState.currentFindingAssets);
  const currentFinding = useRecoilValue(taskState.currentFinding);
  const setLocalFindings = useSetRecoilState(taskState.localFindings);
  const setIsAnnotating = useSetRecoilState(jobState.isAnnotating);

  return useCallback(
    (newLabel: Label, newLabelIndex: number) => {
      if (currentFinding === undefined) {
        return;
      }
      const currentFindingIndex = currentFinding.index;

      setLocalFindings(prev => {
        const array = [...prev];
        const targetAsset = currentFindingAssets.find(
          ({ name }) => name === newLabel.name
        );

        if (!currentFinding?.labels || !targetAsset) {
          return prev;
        }

        const newLabels = (() => {
          const labels = [...currentFinding.labels];
          if (newLabelIndex >= 0) {
            // Replace label with newly selected one.
            labels[newLabelIndex] = newLabel;
          } else {
            labels.push(newLabel);
          }
          return labels;
        })()
          // For resetting value of the children labels in different parent
          // with the currently selected label.
          .map((label, _, labels) => {
            // Find matched asset with the current label.
            const labelAsset = currentFindingAssets.find(
              ({ name }) => name === label.name
            );

            if (!labelAsset) return label;

            const isParentLabelSelected = AssetUtils.isParentLabelSelected(
              labelAsset,
              labels,
              currentFindingAssets
            );

            // reset ONLY if the label is a child/grandchild label AND its parent label value is not selected
            if (labelAsset.parent && !isParentLabelSelected) {
              return {
                ...label,
                value: FindingUtils.getDefaultLabelValue(labelAsset),
              };
            }

            return label;
          });

        array[currentFindingIndex - 1] = {
          ...currentFinding,
          labels: newLabels,
        };

        return array;
      });

      // Make finding unconfirmed when label has changed.
      confirmFinding(currentFindingIndex, false);
      setIsAnnotating(true);
    },
    [
      confirmFinding,
      currentFinding,
      currentFindingAssets,
      setIsAnnotating,
      setLocalFindings,
    ]
  );
};

export default useSetFindingLabel;
