import {
  ReactNode,
  MouseEventHandler,
  CSSProperties,
  useState,
  Children,
  isValidElement,
  cloneElement,
  useEffect,
} from 'react';

import { useRecoilValue } from 'recoil';

import Collapse from '@mui/material/Collapse';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import ExpandButton from 'src/components/ExpandButton';
import jobIdList from 'src/states/jobIdList';
import localStore from 'src/utils/localStore';

export interface AsideItemIconButton {
  title: string;
  icon: ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

interface Props {
  label: string;
  children?: ReactNode;
  iconButtons?: ReactNode[];
  expandable?: boolean;
  isDraggable?: boolean;
  style?: CSSProperties;
  isCollapsible?: boolean;
  isCategory?: boolean;
}

const getAsideAreaVisibility = (label: string) => {
  return localStore.getAsidePanelVisibility(label);
};

const AsideItem = ({
  label,
  children,
  iconButtons = [],
  isDraggable = false,
  style,
  isCollapsible,
  isCategory,
}: Props): JSX.Element => {
  const currentJobId = useRecoilValue(jobIdList.currentJobId);
  const [open, setOpen] = useState(getAsideAreaVisibility(label));

  const title = open ? 'Collapse' : 'Expand';
  const toggle = () => {
    if (isCollapsible) {
      setOpen(prev => {
        localStore.setAsidePanelVisibility(label, !prev);
        return !prev;
      });
    }
  };

  // Close category panels when changing jobs
  useEffect(() => {
    if (isCategory) {
      localStore.setAsidePanelVisibility(label, false);
      setOpen(false);
    }
  }, [label, currentJobId, isCategory]);

  return (
    <Container
      $isDraggable={isDraggable}
      style={style}
      data-testid={isCategory ? 'categoryPanel' : 'asidePanel'}
    >
      {(label || !!iconButtons.length) && (
        <Header>
          <HeaderTitle $isCollapsible={!!isCollapsible} onClick={toggle}>
            {label}
          </HeaderTitle>

          <ButtonWrap>
            {Children.map(iconButtons, (button, index) =>
              isValidElement(button)
                ? cloneElement(button, { key: index })
                : button
            )}
            {isCollapsible && (
              <Tooltip
                title={title}
                placement="top-end"
                key="expand-collapse-button"
              >
                <span>
                  <ExpandButton expanded={open} onClick={toggle} />
                </span>
              </Tooltip>
            )}
          </ButtonWrap>
        </Header>
      )}
      {isCollapsible ? (
        <Collapse in={open}>
          <Content>{children}</Content>
        </Collapse>
      ) : (
        <Content>{children}</Content>
      )}
    </Container>
  );
};

export default AsideItem;

const Container = styled('div', { name: 'AsideItem' })<{
  $isDraggable: boolean;
}>(({ $isDraggable }) => ({
  borderBottom: $isDraggable
    ? 'none'
    : '1px solid var(--ctl-background-color-lighter)',
  padding: '0.7rem',
  overflowY: $isDraggable ? 'auto' : 'visible',
  boxSizing: 'border-box',
}));

const Header = styled('div', { name: 'AsideItemHeader' })(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '1.5rem',
  color: 'var(--ctl-color)',
}));

const HeaderTitle = styled('div', { name: 'AsideItemHeaderTitle' })<{
  $isCollapsible: boolean;
}>(({ $isCollapsible }) => ({
  flex: 1,
  userSelect: 'none',
  cursor: $isCollapsible ? 'pointer' : 'default',
}));

const ButtonWrap = styled('div')`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  flex: 1;
`;
