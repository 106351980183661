import { useEffect } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import imageState from 'src/states/image';
import { projectState } from 'src/states/project';
import { taskState } from 'src/states/task';

/**
 * This hook is for when user selected a finding
 * which is placed in another view while fullscreen mode
 * is activated.
 */
const useUpdateFullscreenIndex = (): void => {
  const currentFinding = useRecoilValue(taskState.currentFinding);
  const images = useRecoilValue(imageState.dbtViewTypesForCurrentScanType);
  const [fullscreenIndex, setFullscreenIndex] = useRecoilState(
    taskState.fullscreenIndex
  );
  const isDBTProject = useRecoilValue(projectState.isDBT);

  useEffect(() => {
    const handleFindingChanged = () => {
      if (fullscreenIndex === undefined) {
        return;
      }

      let viewerNames = images.map(({ name }) => name);
      const activeFullscreenViewName = viewerNames[fullscreenIndex];
      if (!!currentFinding?.image && isDBTProject) {
        // TODO: Not sure if this is the best fallback string
        const currentFindingScanType =
          currentFinding.image.split('_')[1] || 'FFDM';
        viewerNames = viewerNames.filter(name =>
          name.includes(currentFindingScanType)
        );
      }

      if (
        !!currentFinding?.image &&
        activeFullscreenViewName !== currentFinding?.image
      ) {
        const newFullScreenIndex = viewerNames.indexOf(currentFinding?.image);
        setFullscreenIndex(newFullScreenIndex);
      }
    };

    handleFindingChanged();
  }, [
    currentFinding?.image,
    fullscreenIndex,
    images,
    isDBTProject,
    setFullscreenIndex,
  ]);
};

export default useUpdateFullscreenIndex;
