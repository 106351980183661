import { useRecoilValue } from 'recoil';

import { styled } from '@mui/material/styles';

import ButtonList from 'src/components/task/ButtonList';
import ButtonListItem from 'src/components/task/ButtonListItem';
import { ControlName, FindingShape } from 'src/interfaces';
import useControlButton from 'src/pages/workspace/task/ControlPanel/useControlButton';
import { taskState } from 'src/states/task';

const ExpandedControls = ({
  disabled = false,
}: {
  disabled?: boolean;
}): JSX.Element => {
  const controlButtons = useControlButton();
  const selectedDBTScanType = useRecoilValue(taskState.selectedDBTScanType);
  const conditionalDBTControlDisable = (controlName: ControlName) =>
    (controlName === FindingShape.MULTI_FRAME_POLYGON &&
      selectedDBTScanType !== '3D') ||
    (controlName === FindingShape.POLYGON && selectedDBTScanType === '3D');

  return (
    <>
      {controlButtons.map((group, index) => {
        return group.length === 0 ? null : (
          <ButtonList key={index}>
            {group.map(({ selected, onClick, icon, label, controlName }) => (
              <ButtonWrap key={label}>
                <ButtonListItem
                  key={label}
                  selected={selected}
                  onClick={onClick}
                  startIcon={icon}
                  disabled={
                    disabled || conditionalDBTControlDisable(controlName)
                  }
                  data-testid={`${controlName}-control-btn`}
                >
                  {label}
                </ButtonListItem>
              </ButtonWrap>
            ))}
          </ButtonList>
        );
      })}
    </>
  );
};

export default ExpandedControls;

const ButtonWrap = styled('div')`
  display: flex;
  gap: 2px;
`;
