import { useCallback } from 'react';

import cookie from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useRecoilRefresher_UNSTABLE, useSetRecoilState } from 'recoil';

import { modalityKey } from 'src/http';
import { ClientError, ClientErrorCode } from 'src/http/client-error';
import { login as loginAPI } from 'src/services/me';
import authState from 'src/states/auth';
import { projectState } from 'src/states/project';

type Return = (id: string, password: string) => Promise<void>;

const useLogin = (): Return => {
  const setMe = useSetRecoilState(authState.me);
  const refreshProjects = useRecoilRefresher_UNSTABLE(projectState.projects);

  const navigate = useNavigate();

  const login = useCallback(
    async (id: string, password: string) => {
      try {
        const me = await loginAPI(id, password);

        if (me.status !== 'SIGNED')
          throw new ClientError({
            code: ClientErrorCode.UNEXPECTED,
          });
        setMe(me);

        refreshProjects();

        const lowerCasePermittedModalities = me.user.permittedModalities
          // 'CCT' is deprecated but some users may still have it in their `permittedModalities`
          .filter(modality => modality !== 'CCT')
          .map(modality => modality.toLowerCase());

        if (lowerCasePermittedModalities.length === 1) {
          cookie.set(modalityKey, lowerCasePermittedModalities[0] || '');
          navigate('/workspace');
        } else {
          navigate('/database', {
            state: { modalities: lowerCasePermittedModalities },
          });
        }
      } catch (error) {
        throw error;
      }
    },
    [navigate, refreshProjects, setMe]
  );

  return login;
};

export default useLogin;
