import { styled } from '@mui/material/styles';

export const StyledViewContainer = styled('div')`
  overflow: hidden;
  position: relative;
  height: 100%;
  min-height: 0;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
`;
