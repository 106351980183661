import CropSquare from '@mui/icons-material/CropSquare';
import { styled } from '@mui/material/styles';

const MultiFramePolygonIcon = (): JSX.Element => {
  return (
    <Container data-testid="multiFramePolygon-icon">
      <CropSquare
        fontSize="inherit"
        sx={{ transform: 'translate(-10%, -10%)' }}
      />
      <LayeredCropSquare
        fontSize="inherit"
        sx={{ transform: 'translate(10%, 10%)' }}
      />
    </Container>
  );
};

export default MultiFramePolygonIcon;

const Container = styled('span')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LayeredCropSquare = styled(CropSquare)`
  position: absolute;
  top: 0;
  left: 0;
`;
