import { useMemo } from 'react';

import { FallbackProps } from 'react-error-boundary';
import { useRecoilValue } from 'recoil';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { ClientError, ClientErrorCode } from 'src/http/client-error';
import jobIdListState from 'src/states/jobIdList';

const ViewerErrorFallback = ({
  error,
  resetErrorBoundary,
}: FallbackProps): JSX.Element => {
  const currentJobId = useRecoilValue(jobIdListState.currentJobId);
  const text = useMemo<{ title: string; desc: string }>(() => {
    if (error instanceof ClientError) {
      switch (error.code) {
        case ClientErrorCode.INVALID_JOB: {
          return {
            title: 'Error happened while preparing viewer',
            desc: 'Please report this job with "New Report" button on left menu.\nThen you can navigate to another job for annotation.',
          };
        }
        case ClientErrorCode.REPORTED_JOB: {
          return {
            title: 'Reported job encountered',
            desc: 'This job cannot be displayed because it has been reported.\nYou can continue after navigating to another job.',
          };
        }
        default: {
          return { title: '', desc: '' };
        }
      }
    }

    return { title: '', desc: '' };
  }, [error]);

  return (
    <Container role="alert" data-testid="viewer-error-fallback">
      <StyledCard>
        <CardContent>
          <Typography
            variant="h5"
            component="h1"
            style={{ marginBottom: '2rem' }}
          >
            {text.title}
          </Typography>
          <Typography
            variant="body1"
            color="error"
            component="div"
            fontWeight="bold"
          >
            {error.message}
          </Typography>
          <StyledCode>Job ID: {currentJobId}</StyledCode>

          <Typography
            variant="body1"
            color="textSecondary"
            component="pre"
            style={{ marginTop: '1rem' }}
          >
            {text.desc}
          </Typography>
          {text.title !== 'Reported job encountered' && (
            <Button
              variant="contained"
              onClick={resetErrorBoundary}
              fullWidth
              style={{ marginTop: '2rem' }}
            >
              Try again
            </Button>
          )}
        </CardContent>
      </StyledCard>
    </Container>
  );
};

export default ViewerErrorFallback;

const Container = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
`;

const StyledCard = styled(Card)`
  background-color: transparent;
  background-image: none;
`;

const StyledCode = styled('code')`
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.85rem;
`;
