import { CubeGrid } from 'styled-spinkit';

import { styled } from '@mui/material/styles';

interface Props {
  flexible?: boolean;
}

const Loading = ({ flexible = false }: Props): JSX.Element => {
  return (
    <Container $flexible={flexible} data-testid="cube-loading-indicator">
      <CubeGrid color="#00a5c8" size={40} />
    </Container>
  );
};

const Container = styled('div')<{ $flexible?: boolean }>(
  ({ $flexible }) => `
  width: ${$flexible ? '100%' : '100vw'};
  height: ${$flexible ? '100%' : '100vh'};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${$flexible ? 'transparent' : 'black'};
`
);

export default Loading;
