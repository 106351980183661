import {
  ChangeEvent,
  MouseEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  FocusEventHandler,
} from 'react';

import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';

import CheckCircle from '@mui/icons-material/CheckCircle';
import Edit from '@mui/icons-material/Edit';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Tooltip from '@mui/material/Tooltip';

import useMoveByJobIndex from 'src/hooks/currentJobId/useMoveByJobIndex';
import useAlert from 'src/hooks/useAlert';
import controlState from 'src/states/control';
import { jobState } from 'src/states/job';
import jobIdListState from 'src/states/jobIdList';
import jobListState from 'src/states/jobList';

interface Props {
  editIndexMode: boolean;
  setEditIndexMode: Dispatch<SetStateAction<boolean>>;
}

const JobNavigator = ({
  editIndexMode,
  setEditIndexMode,
}: Props): JSX.Element => {
  const { open: openAlert, close: closeAlert } = useAlert();

  const moveByJobIndex = useMoveByJobIndex();

  const jobLoadable = useRecoilValueLoadable(jobState.current);
  const jobListLoadable = useRecoilValueLoadable(jobListState.current);
  const jobList =
    jobListLoadable.state === 'hasValue' ? jobListLoadable.contents : [];
  const setControl = useSetRecoilState(controlState.current);
  const currentJobIndex = useRecoilValue(jobIdListState.currentJobIndex) || 0;
  const [isAnnotating] = useRecoilState(jobState.isAnnotating);

  const [isValidJobIndex, setIsValidJobIndex] = useState(true);
  const [inputValue, setInputValue] = useState(0);

  const availableJobs = jobList.length;

  /**
   * we need this in order to restore incorrect input
   * when user clicks <Chip /> this is called immediately
   * and gives the current value which is currentJobIndex
   * then we calculate jobId based on this
   *
   * @author humoyun
   */
  const inputRef = useCallback((el: HTMLInputElement) => {
    if (el) {
      el.focus();
    }
  }, []);

  const triggerInput = () => {
    setEditIndexMode(!editIndexMode);
  };

  const getTooltipContent = () => {
    if (editIndexMode) {
      return `Stats: ${availableJobs} jobs available`;
    }
    return 'Please click to enter job index manually';
  };

  const handleMouseDown = (e: MouseEvent) => {
    e.preventDefault();
  };

  const handleFocusInput = useCallback<FocusEventHandler<HTMLInputElement>>(
    event => {
      setControl('none');
      (event.target as HTMLInputElement).select();
    },
    [setControl]
  );

  const handleJobIndexChange = (e: ChangeEvent<HTMLInputElement>) => {
    closeAlert();
    if (!e.target.value?.length) {
      setIsValidJobIndex(false);
      return;
    }

    const newInputValue = Number(e.target.value);

    if (isNaN(newInputValue) || newInputValue === 0) {
      openAlert({
        message: 'Please enter numeric value greater than 0',
        type: 'error',
        autoHide: true,
      });

      setIsValidJobIndex(false);
      return;
    }

    if (newInputValue > availableJobs) {
      openAlert({
        message: 'Provided job index is out of boundary',
        type: 'error',
        autoHide: true,
      });

      setIsValidJobIndex(false);
    } else {
      setIsValidJobIndex(true);
    }

    setInputValue(newInputValue);
  };
  const restoreInput = () => {
    setInputValue(currentJobIndex);
    setEditIndexMode(!editIndexMode);
  };
  const handleInputClick = () => {
    if (inputValue > availableJobs) {
      openAlert({
        message: `Provided job index is out of boundary`,
        type: 'error',
        autoHide: true,
      });
      restoreInput();
      return;
    }

    moveByJobIndex(inputValue - 1);
    setEditIndexMode(!editIndexMode);
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter': {
        handleInputClick();
        break;
      }
      case 'Escape': {
        restoreInput();
        break;
      }
      default: {
        e.stopPropagation();
        break;
      }
    }
  };

  useEffect(() => {
    setInputValue(currentJobIndex + 1);
  }, [currentJobIndex]);

  // if there is no completed jobs enabling job-index-input box has no meaning
  const disableInput = jobLoadable.state === 'loading' || isAnnotating;

  return (
    <Tooltip title={getTooltipContent()}>
      {!editIndexMode ? (
        <Chip
          style={{ fontSize: '14px' }}
          label={currentJobIndex + 1}
          clickable
          disabled={disableInput}
          onClick={triggerInput}
          onDelete={triggerInput}
          deleteIcon={
            <Edit fontSize="small" style={{ width: '16px', height: '16px' }} />
          }
          variant="outlined"
          data-testid="input-job-navigator"
        />
      ) : (
        <FormControl error={true} size="small">
          <OutlinedInput
            inputRef={inputRef}
            type="text"
            disabled={disableInput}
            defaultValue={currentJobIndex + 1}
            error={!isValidJobIndex}
            onChange={handleJobIndexChange}
            onKeyUp={handleKeyUp}
            onBlur={restoreInput}
            onFocus={handleFocusInput}
            style={{ width: '100px', paddingRight: 0 }}
            endAdornment={
              <InputAdornment position="end" style={{ marginLeft: 0 }}>
                <IconButton
                  aria-label="toggle edit mode"
                  onClick={handleInputClick}
                  disabled={disableInput}
                  onMouseDown={handleMouseDown}
                >
                  <CheckCircle />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      )}
    </Tooltip>
  );
};

export default JobNavigator;
