import { useMemo, useCallback, SyntheticEvent } from 'react';

import { useRecoilValue } from 'recoil';

import Fullscreen from '@mui/icons-material/Fullscreen';
import FullscreenExit from '@mui/icons-material/FullscreenExit';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

import { StrokeText } from '@InsightViewer/components/StrokeText';

import useSetFullscreenIndex from 'src/hooks/tasks/useSetFullscreenIndex';
import useShortcuts from 'src/hooks/useShortcuts';
import { ShortcutDefinition } from 'src/interfaces/shortcut';
import { taskState } from 'src/states/task';

export interface ExpandViewButtonProps {
  viewIndex: number;
  title: string;
  hideAndDisable?: boolean;
}

export function ExpandViewButton({
  viewIndex,
  title,
  hideAndDisable,
}: ExpandViewButtonProps): JSX.Element {
  const fullscreenIndex = useRecoilValue(taskState.fullscreenIndex);
  const setFullscreenIndex = useSetFullscreenIndex();
  const isFullscreenActivated = fullscreenIndex !== undefined;

  const setFullscreenIndexByShortcut = useCallback(
    (index: number): void => {
      if (hideAndDisable) {
        return;
      }
      setFullscreenIndex(index);
    },
    [hideAndDisable, setFullscreenIndex]
  );

  const shortcuts = useMemo<ShortcutDefinition[]>(
    () => [
      {
        shortcut: `expandPane${
          viewIndex + 1
        }` as ShortcutDefinition['shortcut'],
        callback: () => setFullscreenIndexByShortcut(viewIndex),
      },
    ],
    [setFullscreenIndexByShortcut, viewIndex]
  );

  useShortcuts(shortcuts);

  return (
    <>
      {hideAndDisable ? null : (
        <StyledIconButton
          onClick={(event: SyntheticEvent) => {
            event.preventDefault();
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
            setFullscreenIndex(viewIndex);
          }}
          tabIndex={-1}
        >
          {isFullscreenActivated ? <FullscreenExit /> : <Fullscreen />}
        </StyledIconButton>
      )}
      <StrokeText>
        <text>
          <tspan fill="#8694B1">{title.toUpperCase()}</tspan>
        </text>
      </StrokeText>
    </>
  );
}

const StyledIconButton = styled(IconButton)`
  && {
    border-radius: 0;
    padding: 4px 6px;
    color: #8694b1;
    font-size: 18px;

    .MuiSvgIcon-root {
      font-size: 1em;
      background-color: #000;
    }
  }
`;
