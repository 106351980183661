import { useRecoilValueLoadable } from 'recoil';

import LastPage from '@mui/icons-material/LastPage';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import useMoveToLastJob from 'src/hooks/currentJobId/useMoveToLastJob';
import { jobState } from 'src/states/job';

interface Props {
  editIndexMode: boolean;
}

const GoToLastJobButton = ({ editIndexMode }: Props): JSX.Element => {
  const moveToLastJob = useMoveToLastJob();

  const canGoNextJobLoadable = useRecoilValueLoadable(jobState.canGoNext);
  const canGoNextJob =
    canGoNextJobLoadable.state === 'hasValue' && canGoNextJobLoadable.contents;

  return (
    <Tooltip title="Last Job">
      <span>
        <IconButton
          disabled={!canGoNextJob || editIndexMode}
          onClick={moveToLastJob}
          size="large"
          data-testid="btn-last-job"
        >
          <LastPage />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default GoToLastJobButton;
