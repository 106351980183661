import { useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { SetLabelFunction } from 'src/hooks/tasks/useSetFindingLabel';
import {
  Label,
  Asset as AssetType,
  SelectorAsset,
  AssetForm,
} from 'src/interfaces';
import { jobState } from 'src/states/job';
import { operationModeState } from 'src/states/operationMode';
import { projectState } from 'src/states/project';

import Selector from './Selector';
import Texter from './Texter';
import Toggler from './Toggler';

export interface AssetProps {
  asset: AssetType;
  currentLabels: Label[];
  onChangeLabel: SetLabelFunction;
  testId?: string;
}

const Asset = ({
  asset,
  currentLabels,
  onChangeLabel,
  testId,
}: AssetProps): JSX.Element | null => {
  const isConfirmedProject = useRecoilValue(projectState.isConfirmed);
  const isCurrentModeEditable = useRecoilValue(
    operationModeState.isCurrentModeEditable
  );
  const currentJob = useRecoilValue(jobState.current);

  const labelIndex = useMemo(() => {
    return currentLabels.findIndex(({ name }) => name === asset.name);
  }, [asset, currentLabels]);

  const isLabelViewOnly = useMemo(
    () => currentLabels[labelIndex]?.viewOnly,
    [currentLabels, labelIndex]
  );

  const isLabelImmutable = !!(
    isConfirmedProject ||
    !isCurrentModeEditable ||
    currentJob.reported ||
    isLabelViewOnly
  );

  switch (asset.form) {
    case AssetForm.TOGGLER:
      return (
        <Toggler
          key={asset.name}
          assetText={asset.text}
          assetName={asset.name}
          labels={currentLabels}
          labelIndex={labelIndex}
          isLabelImmutable={isLabelImmutable}
          onChangeLabel={onChangeLabel}
          testId={testId}
        />
      );

    case AssetForm.SELECTOR:
      return (
        <Selector
          key={asset.name}
          asset={asset as SelectorAsset}
          labels={currentLabels}
          labelIndex={labelIndex}
          isLabelImmutable={isLabelImmutable}
          onChangeLabel={onChangeLabel}
          testId={testId}
        />
      );

    case AssetForm.TEXTER:
      return (
        <Texter
          key={asset.name}
          assetText={asset.text}
          assetName={asset.name}
          labels={currentLabels}
          labelIndex={labelIndex}
          isLabelImmutable={isLabelImmutable}
          onChangeLabel={onChangeLabel}
          testId={testId}
        />
      );

    default:
      return null;
  }
};

export default Asset;
