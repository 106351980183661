import { useCallback } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { DBTScanType } from 'src/interfaces/job';
import { projectState } from 'src/states/project';
import { taskState } from 'src/states/task';

import useSetShowDBTScanType from './useSetShowDBTScanType';

const useSetFindingIndex = (): ((
  index: number | undefined,
  toggle?: boolean
) => void) => {
  const setFindingIndex = useSetRecoilState(taskState.findingIndex);
  const findings = useRecoilValue(taskState.findings);
  const isDBTProject = useRecoilValue(projectState.isDBT);
  const setShowDBTScanType = useSetShowDBTScanType();

  const moveFindingIndex = useCallback(
    (index: number | undefined, toggle = true) => {
      const newDBTFinding =
        isDBTProject && findings.find(finding => finding.index === index);
      if (newDBTFinding) {
        const newFindingScanType = newDBTFinding.image.split(
          '_'
        )[1] as DBTScanType;
        setShowDBTScanType(newFindingScanType);
      }

      setFindingIndex(prev =>
        index === prev ? (toggle ? undefined : prev) : index
      );
    },
    [findings, isDBTProject, setFindingIndex, setShowDBTScanType]
  );

  return moveFindingIndex;
};

export default useSetFindingIndex;
