import { useState, MouseEventHandler } from 'react';

import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';

import IssueDate from 'src/components/Issue/IssueDate';
import { IssueUpdate } from 'src/interfaces/issue';
import IssueUtils from 'src/utils/issue';

interface Props {
  createdAt: string;
  updates: IssueUpdate[];
}

const MAX_DISPLAYABLE_LENGTH = 5;

const IssueItemFooter = ({ createdAt, updates }: Props): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);

  const handleClick: MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();

    if (!updates.length) {
      return;
    }

    setIsVisible(prev => !prev);
  };

  const { latestUpdates, restUpdatesCount } = (() => {
    if (updates.length <= MAX_DISPLAYABLE_LENGTH) {
      return {
        latestUpdates: updates,
        restUpdatesCount: 0,
      };
    }
    return {
      latestUpdates: updates.slice(
        updates.length - MAX_DISPLAYABLE_LENGTH,
        updates.length
      ),
      restUpdatesCount: updates.length - MAX_DISPLAYABLE_LENGTH,
    };
  })();

  return (
    <div>
      <Container>
        <IssueDate createdAt={createdAt} />
        <UpdateCountButton
          role="button"
          aria-label="update-count-button"
          data-testid="comment-count"
          area-pressed={isVisible}
          tabIndex={0}
          onClick={handleClick}
        >
          {updates.length} comments
        </UpdateCountButton>
      </Container>

      <Collapse in={isVisible}>
        <UpdatesWrap>
          {!!restUpdatesCount && (
            <StyledDiv key={'rest-count'}>
              <RestUpdatesMessage>
                {restUpdatesCount} more comments exist.
              </RestUpdatesMessage>
            </StyledDiv>
          )}
          {latestUpdates.map(
            ({ type, text, id, createdAt, userId, userName }) => {
              const message = (() => {
                if (IssueUtils.getDeleted({ text, userId })) {
                  return '[Deleted]';
                }
                if (IssueUtils.getDisabledType({ type })) {
                  return `${IssueUtils.getDisabledMessage({
                    type,
                    userName,
                  })}`;
                }
                return text;
              })();

              return (
                <StyledDiv key={id}>
                  <UpdateText>{message}</UpdateText>
                  <IssueDate createdAt={createdAt} />
                </StyledDiv>
              );
            }
          )}
        </UpdatesWrap>
      </Collapse>
    </div>
  );
};

export default IssueItemFooter;

const Container = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
`;

const UpdateCountButton = styled('div')`
  color: rgba(255, 255, 255, 0.5);
`;

const UpdateText = styled('div')`
  flex: 1;
  margin-bottom: 0.125rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
`;

const StyledDiv = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
  padding-top: 0.5rem;
  font-size: 0.85rem;

  & ~ & {
    margin-top: 0.5rem;
    border-top: 1px solid var(--ctl-background-color-lighter);
  }
`;

const UpdatesWrap = styled('div')`
  margin-top: 0.5rem;
  border-top: 1px solid var(--ctl-background-color-lighter);
`;

const RestUpdatesMessage = styled('div')`
  opacity: 0.5;
  align-self: center;
`;
