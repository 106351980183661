import { useState, useRef, ReactNode, MouseEventHandler } from 'react';

import { useRecoilValue } from 'recoil';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

import useAlert from 'src/hooks/useAlert';
import useConfirmModal from 'src/hooks/useConfirmModal';
import useErrorHandler from 'src/hooks/useErrorHandler';
import {
  toggleCloseIssue as toggleCloseIssueService,
  deleteUpdate as deleteUpdateService,
  editUpdate as editUpdateService,
} from 'src/services/issue';
import authState from 'src/states/auth';
import { useRefreshIssues } from 'src/states/issues';
import { useRefreshJobList } from 'src/states/jobList';
import IssueUtils from 'src/utils/issue';

import IssueActions from './IssueActions';
import IssueDate from './IssueDate';
import IssueItem, { IssueItemProps } from './IssueItem';

interface EditableIssueProps extends Omit<IssueItemProps, 'actionElement'> {
  text: string | null;
  createdAt: string;
  issueId: string;
  updateId: string;
  index?: number;
  userId: string | null;
  isFirstUpdate?: boolean;
  maxRowsOnContent?: number;
  footerElement?: ReactNode;
  closed?: boolean;
  onClick?: () => void;
}

const EditableIssueItem = ({
  text,
  createdAt,
  issueId,
  updateId,
  index,
  userId,
  isFirstUpdate,
  maxRowsOnContent,
  footerElement,
  onClick,
  closed,
  ...rest
}: EditableIssueProps): JSX.Element => {
  const myId = useRecoilValue(authState.myId);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const refreshIssues = useRefreshIssues();
  const refreshJobList = useRefreshJobList();
  const { getConfirmation } = useConfirmModal();

  const editUpdate = useErrorHandler(editUpdateService);
  const deleteUpdate = useErrorHandler(deleteUpdateService);
  const toggleCloseIssue = useErrorHandler(toggleCloseIssueService);
  const { open: openAlert } = useAlert();

  const handleClickCancelEdit: MouseEventHandler<
    HTMLButtonElement
  > = async e => {
    e.stopPropagation();
    setIsEditing(false);
  };

  const handleClickEdit: MouseEventHandler<HTMLButtonElement> = async e => {
    e.stopPropagation();
    if (!inputRef.current?.value || myId !== userId) return;
    try {
      await editUpdate({
        updateId,
        text: inputRef.current?.value,
      });
      inputRef.current.value = '';
      refreshIssues();
    } catch (error) {
      openAlert({
        type: 'error',
        message: `Failed to edit the comment: ${(error as Error).message}`,
      });
    } finally {
      setIsEditing(false);
    }
  };

  const handleClickDelete = async () => {
    if (myId !== userId) return;
    const confirmed = await getConfirmation({
      title: 'Delete comment',
      description:
        'Are you sure you want to delete this comment? This action cannot be undone.',
      confirmButtonText: 'Delete',
    });
    if (!confirmed) return;
    try {
      await deleteUpdate({
        updateId,
      });
      refreshIssues();
    } catch (error) {
      openAlert({
        type: 'error',
        message: `Failed to delete the comment: ${(error as Error).message}`,
      });
    }
  };

  const handleClickClose = async () => {
    try {
      await toggleCloseIssue({
        issueId: issueId,
        closed: !closed,
      });
      refreshIssues();
      refreshJobList();
    } catch (error) {
      openAlert({
        type: 'error',
        message: `Failed to ${closed ? 'reopen' : 'close'} the issue: ${
          (error as Error).message
        }`,
      });
    }
  };

  const deleted = IssueUtils.getDeleted({ text, userId });
  const isMine = myId === userId;
  const disableAction = !isMine || deleted;
  const contents = deleted ? 'This comment is deleted.' : text;

  return (
    <IssueItem
      {...rest}
      index={index}
      actionElement={
        <IssueActions
          isFirstUpdate={isFirstUpdate}
          onEdit={() => {
            if (myId === userId) {
              setIsEditing(true);
            }
          }}
          onDelete={handleClickDelete}
          onCloseIssue={handleClickClose}
          deleted={deleted}
          closed={closed}
          disableEdit={disableAction}
          disableDelete={disableAction}
        />
      }
      onClick={onClick}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
        }}
      >
        {isEditing ? (
          <>
            <TextField
              placeholder="Edit a comment..."
              variant="outlined"
              fullWidth
              multiline
              size="small"
              inputRef={inputRef}
              defaultValue={contents}
              onClick={e => e.stopPropagation()}
            />
            <EditButtonDiv>
              <Button
                size="small"
                fullWidth
                variant="outlined"
                onClick={handleClickCancelEdit}
              >
                Cancel
              </Button>
              <Button
                size="small"
                fullWidth
                variant="contained"
                onClick={handleClickEdit}
              >
                Edit
              </Button>
            </EditButtonDiv>
          </>
        ) : (
          <>
            <FeedbackContent
              $maxRows={maxRowsOnContent}
              data-testid="issue-text"
            >
              {contents}
            </FeedbackContent>
            {footerElement ? (
              footerElement
            ) : (
              <IssueDate createdAt={createdAt} />
            )}
          </>
        )}
      </div>
    </IssueItem>
  );
};

const FeedbackContent = styled('div')<{ $maxRows?: number }>(
  ({ $maxRows }) => `
  white-space: pre-wrap;
  word-break: break-word;
  ${
    $maxRows
      ? `
  display: -webkit-box;
  -webkit-line-clamp: ${$maxRows};
  -webkit-box-orient: vertical;
  overflow: hidden;
  `
      : ''
  }
`
);

const EditButtonDiv = styled('div')`
  margin-bottom: 0.25rem;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
`;

export default EditableIssueItem;
