import {
  ClaimSchema,
  ClaimAssetSchema,
  ClaimModeSchema,
  ClaimOverrideSchema,
  ClaimViewerSchema,
  DrawNameEnum,
  TraverseNameEnum,
  EtcNameEnum,
  ManipulateNameEnum,
  ModalityEnum,
} from '@lunit-io/ctl-api-interface';

import { FindingShape } from './job';

export enum ProjectType {
  XRAY = 'xray',
  MAMMOGRAPHY = 'mammography',
  COMPUTED_TOMOGRAPHY = 'computed_tomography',
  TOMOSYNTHESIS = 'tomosynthesis',
}

export enum AssetForm {
  TOGGLER = 'toggler',
  SELECTOR = 'selector',
  TEXTER = 'texter',
}

// Mode can be in WORKING, AI PREDICTION states or other depending on the project
// here we have two states, each state has isEditable field, if true then
// drawing is allowed otherwise not
// [WORKING.isEditable = true, AI_PREDICTION.isEditable = false]
export type OperationMode = Pick<
  ClaimModeSchema,
  'name' | 'text' | 'isEditable'
>;

export type TogglerAsset = Pick<
  ClaimAssetSchema,
  'id' | 'name' | 'group' | 'form' | 'text' | 'parent' | 'formAttributes'
>;

export type SelectorAsset = Pick<
  ClaimAssetSchema,
  'id' | 'name' | 'group' | 'form' | 'text' | 'parent' | 'formAttributes'
>;

export type TexterAsset = Pick<
  ClaimAssetSchema,
  'id' | 'name' | 'group' | 'form' | 'text' | 'parent'
>;

export type Asset = TogglerAsset | SelectorAsset | TexterAsset;
export type Override = Pick<ClaimOverrideSchema, 'filter' | 'actions'>;
export type Viewer = Pick<ClaimViewerSchema, 'type' | 'images'>;

// main schema for describing annotation workflow
export type Claim = Pick<
  ClaimSchema,
  | 'modes'
  | 'assets'
  | 'overrides'
  | 'viewer'
  | 'assetValidations'
  | 'controls'
  | 'invisibleComponents'
  | 'type'
  | 'categories'
  // TODO: Uncomment this when ctl-api-interface is updated
  // | 'modality'
>;

// types of users
export type Annotator = {
  id: string;
  currentJobId?: string | null;
  name?: string;
};
// diff between Annotator and Associate: just for semantic purpose
export type Associate = Annotator;

export type Reviewer = {
  id: string;
  associates: Associate[];
};
export interface RemoteProject {
  id: string;
  claim: ClaimSchema;
  name: string;
  modality: ModalityEnum;
  annotators: Annotator[];
  reviewers: Reviewer[];
  createdAt: string;
  confirmed: boolean;
  validated?: boolean;
  validatedAt?: boolean;
  total?: boolean;
  annotated?: boolean;
}

export interface Project {
  id: string;
  claim: Claim;
  maxJob?: number;
  annotators: Annotator[];
  userId: string;
  associates: Associate[];
  isReviewer: boolean;
  reviewers: Reviewer[];
  name: string;
  confirmed: boolean;
  modality: ModalityEnum;
}

export interface RemoteProjectListResp {
  countPerPage: number;
  countTotalObjects: number;
  countTotalPages: number;
  currentPage: number;
  nextPage: string;
  previousPage: string;
  projects: RemoteProject[];
}

export function getDefaultProject(): Project {
  return {
    id: '',
    claim: {
      controls: [],
      modes: [],
      assets: [],
      viewer: {
        type: 'xray',
        images: [],
      },
    },
    confirmed: false,
    annotators: [],
    userId: '',
    associates: [],
    isReviewer: false,
    reviewers: [],
    name: '',
    modality: 'CXR',
  };
}

export type ControlName =
  | DrawNameEnum
  | FindingShape
  | TraverseNameEnum
  | EtcNameEnum
  | ManipulateNameEnum
  | 'issuePoint';
