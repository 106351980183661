import { useEffect } from 'react';

import { useRecoilValueLoadable } from 'recoil';

import useEnqueueSingleJob from 'src/hooks/job/useEnqueueSingleJob';
import useInitProject from 'src/hooks/project/useInitProject';
import useInitTask from 'src/hooks/tasks/useInitTask';
import useResetFindings from 'src/hooks/tasks/useResetFindings';
import useResetIssueControl from 'src/hooks/tasks/useResetIssueControl';
import useControlShortcuts from 'src/hooks/useControlShortcuts';
import useTaskShortcuts from 'src/pages/workspace/useTaskShortcuts';
import { jobState } from 'src/states/job';

const TaskInitializer = (): JSX.Element | null => {
  const jobLoadable = useRecoilValueLoadable(jobState.current);

  const initTask = useInitTask();

  useInitProject();
  useResetFindings();
  useEnqueueSingleJob();

  // Set shortcuts
  useControlShortcuts();
  useTaskShortcuts();

  useResetIssueControl();

  // Prepare readings
  useEffect(() => {
    if (jobLoadable.state !== 'hasValue') {
      return;
    }
    initTask(jobLoadable.contents);
  }, [initTask, jobLoadable.state, jobLoadable.contents]);

  return null;
};

export default TaskInitializer;
