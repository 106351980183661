import {
  SelectorAttributes,
  ClaimAssetSchema,
  FindingTexterLabel,
  FindingTogglerLabel,
  FindingSelectorLabel,
  ShapeEnum,
} from '@lunit-io/ctl-api-interface';
import groupBy from 'lodash.groupby';

import { Point } from '@InsightViewer/types';

import {
  LocalFinding,
  FindingContour,
  FindingLabel,
  AssetForm,
  FindingShape,
  ViewNameType,
} from 'src/interfaces';

type ValidatedLabels = { group: string; label: FindingLabel | undefined }[];

type FindingLabelType =
  | FindingTogglerLabel
  | FindingTexterLabel
  | FindingSelectorLabel;

/**
 * This function checks if label names are equivalent. See below for more details.
 * The matching criteria for equivalent strings are:
 * 1. exact matches of each other OR
 * 2. one string is a substring of the other AND has the `m_` prefix AND has the same length except for the `m_` prefix OR
 * 3. `polygonType` and `multiFramePolygonType`
 *
 * These criteria seem safe because BE manually generates projects and follows this pattern.
 * This a necessary temporary hack to sync 2D and 3D findings until we implement an ontology system 🤞 or a different way to show which finding labels are equivalent
 */
export const areLabelNamesMatched = (
  string1: string,
  string2: string
): boolean => {
  const areStringsExactMatch = string1 === string2;
  // exact string matches are always equivalent
  if (areStringsExactMatch) return true;

  // `multiFramePolygonType` and `polygonType` should always match
  const areStringsPolygonAndMultiFramePolygon =
    (string1 === 'polygonType' && string2 === 'multiFramePolygonType') ||
    (string1 === 'multiFramePolygonType' && string2 === 'polygonType');
  if (areStringsPolygonAndMultiFramePolygon) return true;

  const RegEx1 = new RegExp(string1, 'i');
  const RegEx2 = new RegExp(string2, 'i');
  /**
   * Except for `multiFramePolygonType`, 3D finding labels use the following pattern for their names:
   *   - `3DFindingLabelName` = `m_` + `2DFindingLabelName`
   *   - example: `m_birads` = `m_` + `birads`
   */
  const doesOneStringContainTheOther =
    RegEx1.test(string2) || RegEx2.test(string1);
  const doesOneStringStartWithM_Prefix =
    string1.startsWith('m_') || string2.startsWith('m_');

  /**
   * Some finding labels are substrings of each other AND have the `m_` prefix, but are NOT equivalent.
   * This is why it's important to check their length.
   * Equivalent labels be substrings of each other AND should have the SAME length, except for the `m_` prefix
   *  - examples:
   *    - ❌ NOT equivalent: `lesionCalcification` != `m_lesionCalcificationType`
   *    - ✅ equivalent: `lesionCalcification` == `m_lesionCalcification`
   */
  const areStringsSameLengthButWithM_Prefix =
    string1.length === string2.length + 2 ||
    string1.length + 2 === string2.length;

  return (
    doesOneStringContainTheOther &&
    doesOneStringStartWithM_Prefix &&
    areStringsSameLengthButWithM_Prefix
  );
};

const FindingUtils = (() => {
  const addConfirmedField = (finding: LocalFinding): LocalFinding => ({
    ...finding,
    confirmed: true,
  });

  const removeConfirmedField = ({
    confirmed,
    ...rests
  }: LocalFinding): LocalFinding => rests;

  const getContourFromFinding =
    (imageWidth: number, imageHeight: number) =>
    (finding: LocalFinding): FindingContour => {
      const points = finding.points as Point[];
      return {
        id: finding.index,
        label: `${finding.alias || ''} [${finding.index}]`,
        polygon: points.map(([y, x]) => {
          return [x * imageWidth, y * imageHeight];
        }),
        shape: finding.shape,
        hidden: finding.hidden,
        viewOnly: finding.viewOnly,
        group: finding.group,
      };
    };

  // Finding들의 array인 targets 내에 key와 동일한 finding을 찾아주는 헬퍼 함수
  const getSameFinding = (
    key: LocalFinding,
    targets: LocalFinding[]
  ): LocalFinding | undefined =>
    targets.find(
      value =>
        value.image === key.image &&
        JSON.stringify(value.points) === JSON.stringify(key.points)
    );

  const getAllAssetsForFinding = (
    findingShape: ShapeEnum,
    assets: ClaimAssetSchema[]
  ): ClaimAssetSchema[] => {
    const currentGroup = `finding/${findingShape}`;
    return assets.filter(asset => asset.group === currentGroup);
  };

  // 1. Get matched assets from the project for the current finding.
  // 2. Map the matched assets to the default labels.
  const getDefaultLabels = (
    targetFindingShape: ShapeEnum,
    assets: ClaimAssetSchema[]
  ): FindingLabel[] =>
    getAllAssetsForFinding(targetFindingShape, assets).map(asset => {
      return {
        name: asset.name,
        value: getDefaultLabelValue(asset),
        viewOnly: false,
      };
    });

  const clampedValue = (min: number, max: number, value: number) =>
    Math.max(min, Math.min(max, value));

  const newCurrentFrameFromMouseWheel = (
    deltaY: number,
    currentFrame: number,
    frameLimits: number[]
  ) => {
    const orderedLimits = frameLimits.sort((a, b) => a - b);
    const minFrame = orderedLimits[0] || 0;
    const maxFrame = orderedLimits[1] || 0;
    const directionFactor = deltaY > 0 ? 1 : -1;
    const increment = directionFactor * Math.ceil(Math.abs(deltaY / 25));
    const newCurrentFrame = currentFrame + increment;
    const clampedNewCurrentFrame = clampedValue(
      minFrame,
      maxFrame,
      newCurrentFrame
    );
    return clampedNewCurrentFrame;
  };

  const getDefaultLabelValue = ({ form, formAttributes }: ClaimAssetSchema) => {
    switch (form) {
      case AssetForm.SELECTOR:
        return (formAttributes as SelectorAttributes).categories
          .map(({ name }) => name)
          .reduce(
            (prev, curr) => ({
              ...prev,
              [curr]: false,
            }),
            {}
          );
      case AssetForm.TOGGLER:
        return false;
      case AssetForm.TEXTER:
      default:
        return '';
    }
  };

  const getGroupLaterality = (
    findingGroup: LocalFinding[]
  ): string | undefined => {
    const [firstFinding] = findingGroup;
    if (!firstFinding) throw new Error('Empty finding group');

    const { image } = firstFinding;
    const [lateralityOfGroup] = image;
    return lateralityOfGroup;
  };

  // TODO: Revise this comment and code
  // grouped findings should be checked against the following rule:
  //   (DBT only) it is desired (ideal) that for each lesion there should be findings in other corresponding views (laterality)
  //   each group should have all 4 L views or all 4 R views, e.g.: [RCC_FFDM, RCC_3D, RMLO_FFDM, RMLO_3D]
  const hasAnyUndesiredUngroupedFinding = (
    findings: LocalFinding[],
    viewTypes: ViewNameType[]
  ): boolean => {
    let undesiredGroupCount = 0;

    const editableFindings = findings.filter(f => !f.viewOnly);
    const groupedFindings = groupBy(editableFindings, 'group');

    Object.keys(groupedFindings).forEach(groupName => {
      const groupFindings = groupedFindings[groupName];
      if (!groupFindings) return;
      const lateralityOfGroup = getGroupLaterality(groupFindings);
      const allViewTypesWithGroupLaterality = viewTypes.filter(
        viewType => viewType.name[0] === lateralityOfGroup
      );
      const expectedGroupCount = allViewTypesWithGroupLaterality.length;

      if (groupFindings?.length !== expectedGroupCount) {
        undesiredGroupCount += 1;
      }
    });

    return undesiredGroupCount > 0;
  };

  // TODO: Revise this comment and code
  // (DBT only) find and return any unmatched finding labels (matching of their values)
  // 1. check within 2D findings (makes sense only there are 2 findings)
  // 2. check within 3D findings (makes sense only there are 2 findings)
  // 3. check across 2D and 3D findings only if they have some overlapping in their labels
  const getUnmatchedFindingLabels = (
    findings: LocalFinding[]
  ): ValidatedLabels => {
    const groupedFindings = groupBy<LocalFinding>(
      findings.filter(f => !f.viewOnly),
      'group'
    );

    // Convert the object to an array of key-value pairs
    const entries = Object.entries(groupedFindings);
    const unmatchedLabels: ValidatedLabels = [];

    // iterate through each [group, findings] pair
    for (const [groupName, localFindings] of entries) {
      localFindings.forEach((finding, i) => {
        const otherFindings = localFindings.filter((_, j) => i !== j);
        const unmatchedLabel = findUnmatchedFindingLabel(
          finding,
          otherFindings
        );
        if (unmatchedLabel)
          unmatchedLabels.push({ group: groupName, label: unmatchedLabel });
      });
    }

    return unmatchedLabels;
  };

  const isFocused =
    (findingIndex: number | undefined, currentGroupName: string | undefined) =>
    (contour: FindingContour) =>
      contour.id === findingIndex || contour.group === currentGroupName;

  const syncedLabelsWithOriginalNames = (
    masterFindingLabels: FindingLabelType[],
    findingLabels: FindingLabelType[]
  ) => {
    return findingLabels.map(findingLabel => {
      const masterFindingLabelSyncSource = masterFindingLabels.find(
        masterFindingLabel =>
          areLabelNamesMatched(masterFindingLabel.name, findingLabel.name)
      );
      if (masterFindingLabelSyncSource === undefined) return findingLabel;

      const { name, ...masterFindingLabelPropertiesToSync } =
        masterFindingLabelSyncSource;

      const syncedLabelWithOriginalName = {
        name: findingLabel.name,
        ...masterFindingLabelPropertiesToSync,
      };
      return syncedLabelWithOriginalName;
    });
  };

  const isPrimaryFrameOutOfRange = (
    primaryFrame: number | undefined,
    startFrame: number | undefined,
    endFrame: number | undefined
  ) => {
    if (
      typeof startFrame !== 'number' ||
      typeof endFrame !== 'number' ||
      typeof primaryFrame !== 'number'
    ) {
      return true;
    }

    return primaryFrame < startFrame || primaryFrame > endFrame;
  };

  const syncedFindings = (
    masterFinding: LocalFinding,
    allFindings: LocalFinding[]
  ) =>
    allFindings.map(finding => {
      if (finding.group !== masterFinding.group) return finding;

      if (finding.shape === FindingShape.MULTI_FRAME_POLYGON) {
        const { startFrame, endFrame, primaryFrame } = finding ?? {};

        if (isPrimaryFrameOutOfRange(primaryFrame, startFrame, endFrame)) {
          return finding;
        }
      }

      const masterFindingLabels = masterFinding.labels;
      if (masterFindingLabels === undefined) return finding;

      const findingLabels = finding.labels;
      if (findingLabels === undefined) return finding;

      const syncedFinding = {
        ...finding,
        labels: syncedLabelsWithOriginalNames(
          masterFindingLabels,
          findingLabels
        ),
        confirmed: true,
      };
      return syncedFinding;
    });

  const circleNumbers: { [k: string]: string } = {
    '1': '①',
    '2': '②',
    '3': '③',
    '4': '④',
    '5': '⑤',
    '6': '⑥',
    '7': '⑦',
    '8': '⑧',
  };

  const focusedContourColor = 'rgb(255, 194, 17)';
  const whiteColor = 'rgb(255, 255, 255)';

  return Object.freeze({
    addConfirmedField,
    removeConfirmedField,
    getContourFromFinding,
    getSameFinding,
    getAllAssetsForFinding,
    getDefaultLabels,
    getDefaultLabelValue,
    newCurrentFrameFromMouseWheel,
    hasAnyUndesiredUngroupedFinding,
    getUnmatchedFindingLabels,
    isFocused,
    syncedLabelsWithOriginalNames,
    isPrimaryFrameOutOfRange,
    syncedFindings,
    circleNumbers,
    focusedContourColor,
    whiteColor,
  });
})();

const findUnmatchedFindingLabel = (
  baseFinding: LocalFinding,
  otherFindings: LocalFinding[]
): FindingLabel | undefined => {
  // TODO: TBD
  // const unmatchedFinding = {};
  for (const targetFinding of otherFindings) {
    for (const baseFindingLabel of baseFinding?.labels || []) {
      // if there is no corresponding label in the targetFinding then no need to check others values
      const targetFindingLabels = targetFinding.labels?.map(l => l.name);
      const doesFindingLabelWithMatchingNameExist = targetFindingLabels?.some(
        l => areLabelNamesMatched(l, baseFindingLabel.name)
      );
      if (!doesFindingLabelWithMatchingNameExist) {
        return baseFindingLabel;
      }

      // iterate over all sub values: value = { val1: false, val2: true, val3: false, ... }
      if (typeof baseFindingLabel.value === 'object') {
        const targetLabel = targetFinding.labels?.find(l => {
          return areLabelNamesMatched(l.name, baseFindingLabel.name);
        })?.value as { [k: string]: boolean };

        for (const keyOfVal of Object.keys(baseFindingLabel.value)) {
          if (targetLabel[keyOfVal] !== baseFindingLabel.value[keyOfVal]) {
            return baseFindingLabel;
          }
        }
      }

      if (
        typeof baseFindingLabel.value === 'string' ||
        typeof baseFindingLabel.value === 'boolean'
      ) {
        const targetLabelValue = targetFinding.labels?.find(l =>
          areLabelNamesMatched(l.name, baseFindingLabel.name)
        )?.value as string | boolean;
        if (baseFindingLabel.value !== targetLabelValue) {
          return baseFindingLabel;
        }
      }
    }
  }

  return undefined;
};

export default FindingUtils;
