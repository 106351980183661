import { useRecoilValue } from 'recoil';

import { LocalJob } from 'src/interfaces';
import { jobState } from 'src/states/job';

const useGetSafeCurrentJob = (): LocalJob | null => {
  try {
    return useRecoilValue(jobState.current);
  } catch (error) {
    return null;
  }
};

export default useGetSafeCurrentJob;
