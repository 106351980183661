import { useMemo } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import useResetControls from 'src/hooks/useResetControls';
import useShortcuts from 'src/hooks/useShortcuts';
import { FindingShape } from 'src/interfaces';
import {
  ShortcutDefinition,
  ShortcutSupportControlName,
} from 'src/interfaces/shortcut';
import controlState from 'src/states/control';
import { jobState } from 'src/states/job';
import { projectState } from 'src/states/project';
import { taskState } from 'src/states/task';
import { confirmedProjectShortcuts } from 'src/utils/claim';

const useControlShortcuts = (): void => {
  const {
    claim: { controls },
  } = useRecoilValue(projectState.current);
  const setControl = useSetRecoilState(controlState.current);
  const setFlip = useSetRecoilState(controlState.flip);
  const setInvert = useSetRecoilState(controlState.invert);
  const selectedDBTScanType = useRecoilValue(taskState.selectedDBTScanType);
  const isConfirmedProject = useRecoilValue(projectState.isConfirmed);
  const currentJob = useRecoilValue(jobState.current);
  const resetControls = useResetControls();

  const shortcuts = useMemo<ShortcutDefinition[]>(() => {
    const controlNames = controls.map(({ name }) => name);

    const items: ShortcutDefinition[] = [
      {
        shortcut: FindingShape.POLYGON,
        callback: () => {
          if (selectedDBTScanType !== '3D') setControl(FindingShape.POLYGON);
        },
      },
      {
        shortcut: FindingShape.MULTI_FRAME_POLYGON,
        callback: () => {
          if (selectedDBTScanType === '3D')
            setControl(FindingShape.MULTI_FRAME_POLYGON);
        },
      },
      {
        shortcut: FindingShape.POINT,
        callback: () => {
          setControl(FindingShape.POINT);
        },
      },
      {
        shortcut: FindingShape.LINE,
        callback: () => {
          setControl(FindingShape.LINE);
        },
      },
      {
        shortcut: 'pan',
        callback: () => {
          setControl('pan');
        },
      },
      {
        shortcut: 'brightness',
        callback: () => {
          setControl('brightness');
        },
      },
      {
        shortcut: 'flip',
        callback: () => {
          setFlip(prev => !prev);
        },
      },
      {
        shortcut: 'invert',
        callback: () => {
          setInvert(prev => !prev);
        },
      },
      {
        shortcut: 'reset',
        callback: resetControls,
      },
    ];

    let conditionalControls = isConfirmedProject
      ? confirmedProjectShortcuts(items)
      : items;

    if (currentJob?.reported) conditionalControls = [];

    return conditionalControls.filter(({ shortcut }) =>
      controlNames.includes(shortcut as ShortcutSupportControlName)
    );
  }, [
    controls,
    isConfirmedProject,
    currentJob?.reported,
    selectedDBTScanType,
    resetControls,
    setControl,
    setFlip,
    setInvert,
  ]);
  useShortcuts(shortcuts);
};

export default useControlShortcuts;
