import { useState, useEffect } from 'react';

import { useIdleTimer } from 'react-idle-timer';
import { useRecoilState, useRecoilValue } from 'recoil';

import useAlert from 'src/hooks/useAlert';
import initialJobIdState from 'src/states/initialJobId';
import { jobState } from 'src/states/job';
import jobIdList from 'src/states/jobIdList';
import jobListState from 'src/states/jobList';
import { projectState } from 'src/states/project';

import useGetSafeCurrentJob from './useGetSafeCurrentJob';

enum IdleState {
  ACTIVE = 'Active',
  IDLE = 'Idle',
  PROMPTED = 'Prompted',
}

type UseTimeTrackerReturn = {
  timeSpent: number;
  idleState: IdleState;
};

export const useTimeTracker = (): UseTimeTrackerReturn => {
  const [timeSpent, setTimeSpent] = useRecoilState(jobState.timeSpent);
  const isConfirmedProject = useRecoilValue(projectState.isConfirmed);
  const currentJobId = useRecoilValue(jobIdList.currentJobId);
  const jobList = useRecoilValue(jobListState.current);
  const annotatorInitialJobId = useRecoilValue(initialJobIdState.annotator);
  const isReviewer = useRecoilValue(projectState.isReviewer);
  const [idleState, setIdleState] = useState<IdleState>(IdleState.ACTIVE);
  const { open: openAlert, close: closeAlert } = useAlert();

  /**
   * Using `useGetSafeCurrentJob` is required to prevent throwing an error at a high level in the component tree.
   * If the regular Recoil atom value is used and there's a `getJob` error, the error component will replace the entire UI
   * and users will not be able to change projects.
   * RAD-3732 added improved error handling to allow users to change projects when an error occurs
   * Using the regulat Recoil atom value will undo this improvement.
   * */
  const currentJob = useGetSafeCurrentJob();

  const onActive = () => {
    setIdleState(IdleState.ACTIVE);
    closeAlert();
  };

  const hasNoInitialAnnotatorJob = annotatorInitialJobId === null;
  const hasNoAnnotatorJobs = jobList.length === 0;
  const isAnnotatorThatDidNotStartProject =
    !isReviewer && hasNoInitialAnnotatorJob;

  const onPrompt = async () => {
    if (isConfirmedProject) return;

    // This only happens if there is a 500 error from the `getJob` API.
    if (currentJob === null) return;

    // Do not show the prompt for reported jobs
    if (currentJob.reported) return;

    // TODO: Add e2e test for a reviewer viewing an annotator without jobs
    if (hasNoAnnotatorJobs) return;
    if (isAnnotatorThatDidNotStartProject) return;
    setIdleState(IdleState.PROMPTED);

    openAlert({
      message: 'Time tracking is paused. Resume annotating to continue.',
      type: 'info',
    });
  };

  const { reset } = useIdleTimer({
    onIdle: () => !isConfirmedProject && setIdleState(IdleState.IDLE),
    onActive,
    onPrompt,
    startOnMount: false,
    timeout: window.Cypress ? 6_000 : 60_000,
    promptBeforeIdle: 200,
    throttle: 1000,
    eventsThrottle: 1000,
  });

  // Reset `timeSpent` when the user switches or saves the current job.
  useEffect(() => {
    if (currentJobId) {
      reset();
      setTimeSpent(0);
    }
  }, [currentJobId, reset, setTimeSpent]);

  // Increment time spent on the current job.
  useEffect(() => {
    const interval = setInterval(() => {
      if (idleState === IdleState.ACTIVE) {
        setTimeSpent(prev => prev + 1000);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [idleState, setTimeSpent]);

  return { timeSpent, idleState };
};
