import {
  ClaimAssetIdentifierSchema,
  ClaimAssetSchema,
} from '@lunit-io/ctl-api-interface';

import { Label } from 'src/interfaces';
import AssetUtils from 'src/utils/asset';

export type AssetValidatorKey = 'ifSet' | 'oneOf';

export type AssetValidatorFunc = (
  identifier: ClaimAssetIdentifierSchema | ClaimAssetIdentifierSchema[],
  labels: Label[],
  assets?: ClaimAssetSchema[]
) => boolean;

const assetValidatorFuncMap: Record<AssetValidatorKey, AssetValidatorFunc> = {
  ifSet: (identifier, labels) => {
    if (Array.isArray(identifier)) {
      throw new Error('"ifSet" validation has wrong definition.');
    }

    const targetLabel = labels.find(label => label.name === identifier.name);

    if (!targetLabel || !identifier.selectedValues) {
      return false;
    }

    return identifier.selectedValues.every(
      AssetUtils.isThisLabelSelected(targetLabel)
    );
  },
  oneOf: (identifiers, labels, assets) => {
    if (!Array.isArray(identifiers)) {
      throw new Error('"oneOf" validation has wrong definition.');
    }

    const message = `One of ${identifiers
      .map(({ text, name }) => {
        /**
         * RAD-6911 requires displaying the display name of the label(s) that fail validation.
         * The display name is only available as asset.text. Labels DO NOT contain this info.
         */
        const assetForThisIdentifier = assets?.find(
          asset => asset.name === name
        );
        if (assetForThisIdentifier) {
          return assetForThisIdentifier.text;
        }
        return `"${text || name}"`;
      })
      .join(', ')} should be selected.`;

    const targetLabels = labels.filter(l =>
      identifiers.find(i => i.name === l.name)
    );

    if (!targetLabels.length) {
      throw new Error(message);
    }

    const isSomeSelected = identifiers.some(
      ({ name }: ClaimAssetIdentifierSchema) => {
        const targetLabel = targetLabels.find(l => l.name === name);
        if (targetLabel === undefined) {
          return false;
        }
        if (typeof targetLabel.value === 'boolean') {
          return targetLabel.value;
        }
        if (typeof targetLabel.value === 'object') {
          return Object.values(targetLabel.value).includes(true);
        }
        return false;
      }
    );

    if (!isSomeSelected) {
      throw new Error(message);
    }

    return true;
  },
};

export default assetValidatorFuncMap;
