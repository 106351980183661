import { useMemo, ChangeEventHandler } from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';

import AssetPanel from 'src/components/LabelPanel/Asset/AssetPanel';
import { SetLabelFunction } from 'src/hooks/tasks/useSetFindingLabel';
import { Label, TogglerLabel } from 'src/interfaces';

export interface TogglerProps {
  assetText: string;
  assetName: string;
  labels: Label[];
  labelIndex: number;
  isLabelImmutable: boolean;
  onChangeLabel: SetLabelFunction;
  testId?: string;
}

const Toggler = ({
  assetText,
  assetName,
  labels,
  labelIndex,
  isLabelImmutable,
  onChangeLabel,
  testId,
}: TogglerProps): JSX.Element => {
  const value = useMemo(
    () => (labels[labelIndex] as TogglerLabel)?.value || false,
    [labels, labelIndex]
  );

  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    onChangeLabel(
      {
        name: assetName,
        value: event.target.checked,
        viewOnly: false,
      },
      labelIndex
    );
  };

  return (
    <AssetPanel data-testid={testId}>
      <StyledFormControlLabel
        control={<StyledCheckbox checked={value} onChange={handleChange} />}
        label={assetText}
        labelPlacement="start"
        disabled={isLabelImmutable}
        data-testid={`${assetText}-toggler-label`}
      />
    </AssetPanel>
  );
};

export default Toggler;

const StyledCheckbox = styled(Checkbox)({
  padding: 0,
  '&$checked': {
    color: 'var(--ctl-brand-color)',
  },
  alignSelf: 'normal',
});

const StyledFormControlLabel = styled(FormControlLabel, {
  name: 'StyledFormControlLabel',
})({
  width: '100%',
  display: 'flex',
  alignItems: 'end',
  justifyContent: 'space-between',
  margin: 0,
  '.MuiFormControlLabel-label': {
    width: '100%',
    minHeight: '1.25rem',
    padding: '0 2px',
    boxSizing: 'content-box',
    fontSize: '0.875rem',
    lineHeight: 'initial',
    color: 'var(--ctl-color)',
    fontWeight: 600,
    '&.Mui-disabled': {
      color: 'var(--ctl-color)',
    },
  },
});
