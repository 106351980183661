import Paper from '@mui/material/Paper';

const ConfirmedProjectNotice = (): JSX.Element => {
  return (
    <Paper
      data-testid="confirmed-project-notice"
      sx={{ outline: '1px solid red', padding: '10px', margin: 'auto' }}
    >
      This project is confirmed and read-only
    </Paper>
  );
};

export default ConfirmedProjectNotice;
