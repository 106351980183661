import { useRecoilValue } from 'recoil';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import useSetOperationMode from 'src/hooks/tasks/useSetOperationMode';
import { operationModeState } from 'src/states/operationMode';
import { projectState } from 'src/states/project';

export default function ModeButtonList(): JSX.Element {
  const project = useRecoilValue(projectState.current);
  const setOperationMode = useSetOperationMode();
  const currentMode = useRecoilValue(operationModeState.current);

  return (
    <>
      {project.claim.modes.map(mode => (
        <ModeButton
          data-testid="mode-button"
          key={mode.text}
          className={mode.name === currentMode.name ? 'active' : ''}
          $isActive={mode.name === currentMode.name}
          onClick={() => {
            setOperationMode(mode);
          }}
        >
          {mode.text}
        </ModeButton>
      ))}
    </>
  );
}

const ModeButton = styled(Button)<{ $isActive: boolean }>(({ $isActive }) => ({
  color: $isActive ? 'var(--ctl-color-active)' : 'var(--ctl-color)',
  fontSize: '13px',
  transform: `translateY(1px)`,
}));
