import { useEffect } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import controlState from 'src/states/control';
import jobIdListState from 'src/states/jobIdList';

const useResetIssueControl = (): void => {
  const jobId = useRecoilValue(jobIdListState.currentJobId);
  const setControl = useSetRecoilState(controlState.current);

  useEffect(() => {
    /**
     * When changing projects, `jobId` first becomes `null` and then receives a value.
     * The `jobId === null` condition ensures the `control` is reset from `issuePoint` as soon as possible.
     * Without this condition, the `control` remains `issuePoint` until `jobId` receives a value
     */
    if (jobId === null || !!jobId)
      // If the `control` is `issuePoint`, reset it to `none`. Otherwise, preserve its value.
      setControl(prev => (prev === 'issuePoint' ? 'none' : prev));
  }, [jobId, setControl]);
};

export default useResetIssueControl;
