import { useTransition, useCallback } from 'react';

import {
  atom,
  selector,
  useRecoilState_TRANSITION_SUPPORT_UNSTABLE,
} from 'recoil';

import {
  getJobList,
  getPairedJobList,
  JobListItem,
  PairedJobListItem,
} from 'src/services/job';
import { isCPCPriorCaseWindow } from 'src/utils/cpc';

import { projectState } from './project';

const refreshCurrent = atom({
  key: 'jobListState/refreshCurrent',
  default: 0,
});

export function useRefreshJobList(): () => void {
  const [, setRefreshState] =
    useRecoilState_TRANSITION_SUPPORT_UNSTABLE(refreshCurrent);
  const [, startTransition] = useTransition();

  return useCallback(() => {
    startTransition(() => {
      setRefreshState(v => v + 1);
    });
  }, [setRefreshState]);
}

const jobList = selector<JobListItem[]>({
  key: 'jobListState/jobList',
  get: async ({ get }) => {
    get(refreshCurrent);
    const { id: projectId } = get(projectState.current);
    const currentAnnotatorId = get(projectState.currentAnnotatorId);

    if (projectId === '') return [];
    const jobs = await getJobList({
      projectId,
      annotatorId: currentAnnotatorId,
    });

    return jobs;
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
});

const pairedJobs = selector<PairedJobListItem[]>({
  key: 'jobListState/pairedJobs',
  get: async ({ get }) => {
    get(refreshCurrent);
    const { id: projectId } = get(projectState.current);
    const currentAnnotatorId = get(projectState.currentAnnotatorId);

    if (projectId === '') return [];
    try {
      const jobs = await getPairedJobList({
        projectId,
        annotatorId: currentAnnotatorId,
      });
      return jobs;
    } catch (e) {
      return [];
    }
  },
});

const current = selector<JobListItem[]>({
  key: 'jobListState/current',
  get: async ({ get }) => {
    const isCPCProject = get(projectState.isCPC);

    if (isCPCProject) {
      const emptyJobListItem = {
        id: '',
        reported: false,
        completed: false,
      };
      const jobs = await get(pairedJobs);

      return isCPCPriorCaseWindow
        ? jobs.map(j => j.prior || emptyJobListItem)
        : jobs.map(j => j.index);
    } else {
      const jobs = await get(jobList);
      return jobs;
    }
  },
});

const jobListState = {
  current,
  pairedJobs,
};

export default jobListState;
