import React, { Suspense } from 'react';

import { useRecoilValue } from 'recoil';

import CloudDoneOutlined from '@mui/icons-material/CloudDoneOutlined';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import Menu from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import HeaderLogoIcon from 'src/components/icons/HeaderLogoIcon';
import { useLayoutState } from 'src/hooks/useLayoutState';
import ConfirmedProjectNotice from 'src/pages/workspace/task/ConfirmedProjectNotice';
import { jobState } from 'src/states/job';
import { projectState } from 'src/states/project';

import ModeButtonList from './ModeButtonList';
import NewIssueButton from './NewIssueButton';

const Header = (): JSX.Element => {
  const isAnnotating = useRecoilValue(jobState.isAnnotating);
  const isConfirmedProject = useRecoilValue(projectState.isConfirmed);

  const { toggleAside, isFullScreen, toggleFullScreen } = useLayoutState();

  return isFullScreen ? (
    <FullScreenExitButton color="error" onClick={toggleFullScreen}>
      <FullscreenExitIcon fontSize="small" />
      <div>Exit Full Screen</div>
    </FullScreenExitButton>
  ) : (
    <Container>
      <LeftSide>
        <MenuItem onClick={toggleAside} data-testid="toggle-aside-panel">
          <Menu />
        </MenuItem>
        <LogoWrapper>
          <HeaderLogoIcon />
        </LogoWrapper>
      </LeftSide>

      {isConfirmedProject && <ConfirmedProjectNotice />}

      <RightSide>
        <Suspense>
          <ModeButtonList />
        </Suspense>
        <Suspense>
          <NewIssueButton />
        </Suspense>

        <SaveStatus>
          {isAnnotating ? (
            <Tooltip title="There are unsaved changes.">
              <CircularProgress
                size={18}
                color="inherit"
                data-testid="annotation-progressing-indicator"
              />
            </Tooltip>
          ) : (
            <Tooltip title="All changes are saved.">
              <CloudDoneOutlined />
            </Tooltip>
          )}
        </SaveStatus>
      </RightSide>
    </Container>
  );
};

export default React.memo(Header);

const FullScreenExitButton = styled(Button)`
  width: 100%;
  height: var(--ctl-header-height);
  gap: 0.25rem;
  border-radius: 0;
`;

const Container = styled('header')`
  background-color: var(--ctl-background-color);
  height: var(--ctl-header-height);
  display: flex;
  justify-content: space-between;
`;

const LeftSide = styled('div')`
  display: flex;
  align-items: center;
`;

const RightSide = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const LogoWrapper = styled('div')`
  box-sizing: border-box;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SaveStatus = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--ctl-header-height);
  height: 100%;
  font-size: 18px;
  color: var(--ctl-color);
  background-color: rgba(0, 0, 0, 0.25);
`;
