import { useLayoutEffect, useRef, useEffect, useCallback } from 'react';

import Draggable, { DraggableEventHandler } from 'react-draggable';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import useAlert from 'src/hooks/useAlert';
import useDraggablePanelPosition from 'src/hooks/useDraggablePanelPosition';
import useErrorHandler from 'src/hooks/useErrorHandler';
import { createIssue as createIssueService } from 'src/services/issue';
import controlState from 'src/states/control';
import issuesState, { useRefreshIssues } from 'src/states/issues';
import jobIdListState from 'src/states/jobIdList';
import { useRefreshJobList } from 'src/states/jobList';
import { taskState } from 'src/states/task';

const CreateIssuePanel = (): JSX.Element | null => {
  const inputRef = useRef<HTMLInputElement>(null);
  const jobId = useRecoilValue(jobIdListState.currentJobId);
  const fullscreenIndex = useRecoilValue(taskState.fullscreenIndex);
  const setControl = useSetRecoilState(controlState.current);

  const { containerRef, offset, setOffset, setOffsetWhenRefElementIsVisible } =
    useDraggablePanelPosition();
  const createIssue = useErrorHandler(createIssueService);
  const { open: openAlert } = useAlert();

  const refreshIssues = useRefreshIssues();
  const refreshJobList = useRefreshJobList();

  const [localIssuePosition, setLocalIssuePosition] = useRecoilState(
    issuesState.localIssuePosition
  );

  const issues = useRecoilValue(issuesState.current);

  const handleDragStop: DraggableEventHandler = (event, data) => {
    setOffset({ x: data.x, y: data.y });
  };

  const handleClose = useCallback(() => {
    setLocalIssuePosition(undefined);
  }, [setLocalIssuePosition]);

  const handleClickSend = async () => {
    if (!inputRef.current?.value || !localIssuePosition || !jobId) return;
    try {
      await createIssue({
        jobId,
        view: localIssuePosition.view,
        update: inputRef.current?.value,
        location: localIssuePosition.location,
      });
      refreshIssues();
      refreshJobList();
    } catch (error) {
      openAlert({
        type: 'error',
        message: `Failed to create a new issue: ${(error as Error).message}`,
      });
    }
    handleClose();
    setControl('none');
  };

  /**
   * use useLayoutEffect for fast null assertion.
   * If use useEffect, contents will be change first before panel disappear.
   */
  useLayoutEffect(() => {
    if (!localIssuePosition) {
      setOffset(undefined);
      return;
    }

    setOffsetWhenRefElementIsVisible(issues.length + 1, true);
  }, [
    issues.length,
    localIssuePosition,
    setOffset,
    setOffsetWhenRefElementIsVisible,
  ]);

  useEffect(() => {
    handleClose();
  }, [handleClose, jobId, fullscreenIndex]);

  return localIssuePosition !== undefined ? (
    <Draggable
      position={offset}
      onStop={handleDragStop}
      nodeRef={containerRef}
      bounds="html"
      handle=".draggable-handle"
    >
      <Container ref={containerRef} style={{ opacity: offset ? 1 : 0 }}>
        <Handle className="draggable-handle">
          <div>Create a new issue</div>
          <div>
            <Tooltip title="Close" placement="top">
              <IconButton
                aria-label="close"
                size="small"
                color="error"
                onClick={handleClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </div>
        </Handle>
        <Content>
          <div
            style={{
              borderTop: '1px solid var(--ctl-background-color-lighter',
              padding: '0.8rem',
            }}
          >
            <TextField
              placeholder="Write comment..."
              variant="outlined"
              fullWidth
              multiline
              size="small"
              inputRef={inputRef}
              autoFocus
              data-testid="input-create-issue"
            />
            <Button
              size="small"
              fullWidth
              variant="contained"
              style={{ marginTop: '.5rem' }}
              onClick={handleClickSend}
              data-testid="btn-create-issue"
            >
              Create a new issue
            </Button>
          </div>
        </Content>
      </Container>
    </Draggable>
  ) : null;
};

export default CreateIssuePanel;

const Container = styled('div')`
  box-sizing: border-box;
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  width: 20rem;
  max-height: 50vh;
  background-color: var(--ctl-background-color-light);
  border: 1px solid var(--ctl-background-color-lightest);
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.6);
`;

const Handle = styled('div')`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0.4rem 0.25rem 0.9rem;
  color: var(--ctl-color);
  background-color: var(--ctl-background-color-dark);
  border-radius: 0.5rem 0.5rem 0 0;
  cursor: move;
`;

const Content = styled('div')`
  box-sizing: border-box;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;
