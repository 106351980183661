import {
  ClaimAssetIdentifierSchema,
  ClaimAssetSchema,
  ClaimAssetValidationSchema,
} from '@lunit-io/ctl-api-interface';

import { Label } from 'src/interfaces';
import assetValidatorFuncMap, {
  AssetValidatorFunc,
  AssetValidatorKey,
} from 'src/utils/AssetValidator/assetValidatorFuncMap';
import { ValidationFailure } from 'src/utils/AssetValidator/errors';

class AssetValidator {
  private assetValidations: ClaimAssetValidationSchema[] = [];
  private sequences: AssetValidatorFunc[][] = [];

  private labels: Label[] = [];
  private findingIndex = 0;
  private assets: ClaimAssetSchema[] = [];

  private setSequences = (
    assetValidations: ClaimAssetValidationSchema[]
  ): void => {
    this.sequences = assetValidations.map(assetValidation =>
      (Object.keys(assetValidation) as AssetValidatorKey[]).map(
        key => assetValidatorFuncMap[key]
      )
    );
  };

  public init = (assetValidations: ClaimAssetValidationSchema[]): void => {
    this.assetValidations = assetValidations;
    this.setSequences(assetValidations);
  };

  public clear = (): void => {
    this.assetValidations = [];
    this.setSequences([]);
  };

  /**
   * If there is an failure while running validations, it will throw ValidationFailure.
   * So when we use this, we need to use try/catch phrase for catching this.
   */
  public validate = (
    labels: Label[],
    findingIndex: number,
    assets?: ClaimAssetSchema[]
  ): void => {
    this.labels = labels;
    this.findingIndex = findingIndex;
    this.assets = assets || [];

    if (!this.sequences.length) {
      return;
    }
    this.sequences.forEach(this.runSequence);
  };

  private getIdentifier = (
    sequenceIndex: number,
    validationName: AssetValidatorKey
  ): ClaimAssetIdentifierSchema | ClaimAssetIdentifierSchema[] => {
    const validation = this.assetValidations[sequenceIndex];
    if (!validation)
      throw new Error(`asset validation is not defined for ${validationName}`);

    return validation[validationName];
  };

  /**
   * This is for running validators sequentially.
   *
   * - If validator function return false    : This sequence will be stopped.
   * - If validator function return true     : Will start to run next validator function.
   * - If validator function throw error     : Stop sequence, and throw ValidationFailure.
   *
   * @param labels
   * @param findingIndex
   * @returns
   */
  private runSequence = (
    sequence: AssetValidatorFunc[],
    sequenceIndex: number
  ): void => {
    sequence.some(assetValidatorFunc => {
      try {
        const identifier = this.getIdentifier(
          sequenceIndex,
          assetValidatorFunc.name as AssetValidatorKey
        );
        // Stop iteration when validator return false
        return !assetValidatorFunc(identifier, this.labels, this.assets || []);
      } catch (error) {
        throw new ValidationFailure(
          this.findingIndex,
          (error as Error).message
        );
      }
    });
  };
}

const assetValidator = new AssetValidator();

export default assetValidator;
