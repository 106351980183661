import { useEffect, useRef } from 'react';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { ShortcutDefinition } from 'src/interfaces/shortcut';
import { jobState } from 'src/states/job';
import { projectState } from 'src/states/project';
import shortcutState from 'src/states/shortcut';
import { confirmedProjectShortcuts } from 'src/utils/claim';
import { keyTester, ShortcutUtils } from 'src/utils/shortcuts';

type Handler = (event: KeyboardEvent) => void;

const DISAPPEAR_DELAY = 800;

const useShortcuts = (items: ShortcutDefinition[]): void => {
  const currentJob = useRecoilValue(jobState.current);
  const setShortcutDefList = useSetRecoilState(shortcutState.definitions);
  const [activated, setActivated] = useRecoilState(shortcutState.activated);
  const timeout = useRef<NodeJS.Timeout>();
  const isConfirmedProject = useRecoilValue(projectState.isConfirmed);

  useEffect(() => {
    const handlers: Handler[] = [];
    const conditionalControls =
      isConfirmedProject || currentJob.reported
        ? confirmedProjectShortcuts(items)
        : items;
    conditionalControls.forEach(item => {
      // Listing up whole shortcut definitions
      setShortcutDefList(prev => {
        const filtered = prev.filter(
          prevItem => prevItem.shortcut !== item.shortcut
        );
        filtered.push(item);
        return filtered;
      });
      // Add event listener
      const { shortcut, validation, callback } = item;
      const handler: Handler = event => {
        const key = ShortcutUtils.getKey(shortcut);
        const tester = keyTester(key);
        const parsedValidation = validation ?? true;
        if (parsedValidation && tester(event)) {
          callback();
          setActivated(undefined);
          setTimeout(() => setActivated(shortcut), 0);
        }
      };
      window.addEventListener('keyup', handler);
      handlers.push(handler);
    });

    return () => {
      handlers.forEach(handler => window.removeEventListener('keyup', handler));
    };
  }, [
    currentJob.reported,
    isConfirmedProject,
    items,
    setActivated,
    setShortcutDefList,
  ]);

  useEffect(() => {
    timeout.current = setTimeout(() => {
      setActivated(undefined);
    }, DISAPPEAR_DELAY);
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = undefined;
      }
    };
  }, [activated, setActivated]);
};

export default useShortcuts;
