import { dataApi } from 'src/http';
import {
  IssueUpdateListSchema,
  IssueReadSchema,
  IssueReadSchemaList,
} from 'src/interfaces/issue';

type IssueUpdateListProps = {
  projectId: string;
  pairType: 'Normal' | 'PairIndex' | 'PairPrior';
};

export const getIssueUpdateList = async ({
  projectId,
  pairType,
}: IssueUpdateListProps): Promise<IssueUpdateListSchema> => {
  const { data } = await dataApi.get(`projects/${projectId}/issues/updates/`, {
    params: { pair_type: pairType, page_size: 10000 },
  });
  return data;
};

export const getIssueList = async (
  jobId: string
): Promise<IssueReadSchema[]> => {
  const { data } = await dataApi.get<IssueReadSchemaList>(
    `jobs/${jobId}/issues`,
    {
      params: {
        page_size: 100,
      },
    }
  );

  return data.issues;
};

type IssueProps = {
  jobId: string;
  update: string;
  view: string;
  location: number[];
};

export const createIssue = async ({
  jobId,
  update,
  view,
  location,
}: IssueProps): Promise<void> => {
  await dataApi.post(`jobs/${jobId}/issues`, {
    update,
    view,
    location,
  });
};

type CreateUpdateProps = {
  issueId: string;
  update: string;
};

export const createUpdate = async ({
  issueId,
  update,
}: CreateUpdateProps): Promise<void> => {
  await dataApi.post(`jobs/issues/${issueId}/updates`, {
    text: update,
  });
};

type EditUpdateProps = {
  updateId: string;
  text: string;
};

export const editUpdate = async ({
  updateId,
  text,
}: EditUpdateProps): Promise<void> => {
  await dataApi.patch(`jobs/issues/updates/${updateId}`, {
    text,
  });
};

type DeleteUpdateProps = {
  updateId: string;
};

export const deleteUpdate = async ({
  updateId,
}: DeleteUpdateProps): Promise<void> => {
  await dataApi.delete(`jobs/issues/updates/${updateId}`);
};

type ToggleCloseIssueProps = {
  issueId: string;
  closed: boolean;
};

export const toggleCloseIssue = async ({
  issueId,
  closed,
}: ToggleCloseIssueProps): Promise<void> => {
  await dataApi.patch(`jobs/issues/${issueId}`, {
    closed,
  });
};

type ToggleReadUpdateProps = {
  updateId: string;
  readStatus: boolean;
};

export const toggleReadUpdate = async ({
  updateId,
  readStatus,
}: ToggleReadUpdateProps): Promise<void> => {
  await dataApi.put(`jobs/issues/updates/${updateId}/read`, {
    readStatus,
  });
};

export const markAllUpdatesAsRead = async (
  projectId: string
): Promise<void> => {
  return dataApi.patch(`projects/${projectId}/updates/read`);
};
