import {
  atom,
  selector,
  selectorFamily,
  waitForAll,
  waitForNone,
} from 'recoil';

import { CornerstoneSingleImage } from '@InsightViewer/image/CornerstoneSingleImage';
import { CornerstoneImage } from '@InsightViewer/image/types';

import { ClientError, ClientErrorCode } from 'src/http/client-error';
import { ViewNameType } from 'src/interfaces/job';
import { getSignedURL } from 'src/services/image';
import {
  CURRENT_DBT_FRAME_NEIGHBORS_PRELOADING_QTY,
  NEXT_DBT_JOB_FRAMES_PRELOADING_QTY,
  localStorageEffect,
} from 'src/utils/localStore';
import { isArray, isNotNull } from 'src/utils/typeHelper';

import { jobState } from './job';
import { taskState } from './task';

const cornerstoneImage = selectorFamily<CornerstoneImage, string>({
  key: 'imageState/cornerstoneImage',
  get: imagePath => async () => {
    const signedUrl = await getSignedURL(imagePath);

    if (!signedUrl) {
      throw new ClientError({
        code: ClientErrorCode.INVALID_IMAGE,
      });
    }

    const cImage = new CornerstoneSingleImage(signedUrl);

    const getImage = () => {
      return new Promise(resolve => {
        if (!cImage) resolve(false);
        cImage.progress.subscribe(loaded => {
          if (loaded === 1) {
            resolve(true);
          }
        });
        cImage.failedImageLoadAttempts.subscribe(failedAttempts => {
          if (failedAttempts > 2) {
            resolve(false);
          }
        });
      });
    };

    const loaded = await getImage();

    if (!loaded) {
      throw new ClientError({
        code: ClientErrorCode.INVALID_IMAGE,
        message: `Failed to load image. Please try again and report job if needed. Image path: ${imagePath}`,
      });
    }

    return cImage;
  },
  dangerouslyAllowMutability: true,
});

const currentJobImagesLoading = selector<void>({
  key: 'imageState/currentJobImagesLoading',
  get: async ({ get }) => {
    const job = get(jobState.current);
    const jobImages = job?.images;

    if (!jobImages) {
      return;
    }

    Object.values(jobImages).map(jobImage => {
      if (isArray(jobImage) || !jobImage.path) {
        return null;
      }
      return get(cornerstoneImage(jobImage.path));
    });
  },
});

// default=0 in local testing and CI testing (to speed up testing, and avoid timeout failure)
const isLocalTestOrCITestEnvironment =
  process.env.REACT_APP_DEPLOYMENT_PHASE?.includes('test');

export const DEFAULT_CURRENT_DBT_FRAME_NEIGHBORS_PRELOADING_QTY =
  isLocalTestOrCITestEnvironment ? 0 : 50;
export const DEFAULT_NEXT_DBT_JOB_FRAMES_PRELOADING_QTY =
  isLocalTestOrCITestEnvironment ? 0 : 50;

const currentDBTFrameNeighborsPreloadingQty = atom({
  key: 'imageState/currentDBTFrameNeighborsPreloadingQty',
  default: Number(
    localStorage.getItem(CURRENT_DBT_FRAME_NEIGHBORS_PRELOADING_QTY) ??
      DEFAULT_CURRENT_DBT_FRAME_NEIGHBORS_PRELOADING_QTY
  ),
  effects: [localStorageEffect(CURRENT_DBT_FRAME_NEIGHBORS_PRELOADING_QTY)],
});

const nextDBTJobFramesPreloadingQty = atom({
  key: 'imageState/nextDBTJobFramesPreloadingQty',
  default: Number(
    localStorage.getItem(NEXT_DBT_JOB_FRAMES_PRELOADING_QTY) ??
      DEFAULT_NEXT_DBT_JOB_FRAMES_PRELOADING_QTY
  ),
  effects: [localStorageEffect(NEXT_DBT_JOB_FRAMES_PRELOADING_QTY)],
});

const nextJobImagesLoading = selector<void>({
  key: 'imageState/nextJobImagesLoading',
  get: async ({ get }) => {
    const nextJob = get(jobState.next);
    const jobImages = nextJob?.images;

    if (!jobImages) {
      return;
    }

    Object.values(jobImages).map(jobImage => {
      if (isArray(jobImage)) {
        return null;
      }

      // All DBT 3D images use jobImage.paths. Others use jobImage.path
      const isDBT3D = isArray(jobImage.paths) && !jobImage.path;
      if (isDBT3D) {
        const nextDBTJobFramesPreloadingQuantity = get(
          imageState.nextDBTJobFramesPreloadingQty
        );
        const slicedPaths = jobImage.paths?.slice(
          0,
          nextDBTJobFramesPreloadingQuantity
        );
        if (!slicedPaths) {
          return null;
        }
        const nextJobImagePaths = get(
          waitForAll(slicedPaths.map(path => cornerstoneImage(path)))
        );
        return nextJobImagePaths;
      }
      // All non-DBT non-3D images use jobImage.path. Only DBT 3D images use jobImage.paths
      if (!isDBT3D && jobImage.path) {
        return get(cornerstoneImage(jobImage.path));
      }

      return null;
    });
  },
});

const imagesPreloading = selectorFamily<void, string[]>({
  key: 'imageState/imagesPreloading',
  get:
    imagePaths =>
    async ({ get }) => {
      const cornerstoneImageLoadable = get(
        waitForNone(imagePaths.map(path => cornerstoneImage(path)))
      );
      cornerstoneImageLoadable
        .filter(({ state }) => state === 'hasValue')
        .map(({ contents }) => contents);
    },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
});

const viewTypes = selector<ViewNameType[]>({
  key: 'imageState/viewTypes',
  get: async ({ get }) => {
    const job = get(jobState.current);
    const jobImages = job?.images;

    const viewNamesAndTypes = Object.entries(jobImages).map(([key, value]) => {
      if (isArray(value)) return null;
      return { name: key, type: value.type };
    });
    return viewNamesAndTypes.filter(isNotNull);
  },
});

const dbt3DViewTypes = selector({
  key: 'imageState/dbt3DViewTypes',
  get: ({ get }) => {
    const viewTypes = get(imageState.viewTypes) as ViewNameType[];
    const actual3DViewTypes = viewTypes.filter(image =>
      image.name.includes('3D')
    );
    if (actual3DViewTypes.length === 4 || actual3DViewTypes.length === 0)
      return actual3DViewTypes;

    return [
      {
        name: 'RCC_3D',
        type: 'multiple',
      },
      {
        name: 'LCC_3D',
        type: 'multiple',
      },
      {
        name: 'RMLO_3D',
        type: 'multiple',
      },
      {
        name: 'LMLO_3D',
        type: 'multiple',
      },
    ];
  },
});

// both MMG and DBT have FFDM view types
const ffdmViewTypes = selector({
  key: 'imageState/ffdmViewTypes',
  get: ({ get }) => {
    const viewTypes = get(imageState.viewTypes) as ViewNameType[];
    const actualFfdmViewTypes = viewTypes.filter(image =>
      image.name.includes('FFDM')
    );
    if (actualFfdmViewTypes.length === 4 || actualFfdmViewTypes.length === 0)
      return actualFfdmViewTypes;
    return [
      {
        name: 'RCC_FFDM',
        type: 'single',
      },
      {
        name: 'LCC_FFDM',
        type: 'single',
      },
      {
        name: 'RMLO_FFDM',
        type: 'single',
      },
      {
        name: 'LMLO_FFDM',
        type: 'single',
      },
    ];
  },
});

const dbtS2DViewTypes = selector({
  key: 'imageState/dbtS2DViewTypes',
  get: ({ get }) => {
    const viewTypes = get(imageState.viewTypes) as ViewNameType[];
    const actualS2DViewTypes = viewTypes.filter(image =>
      image.name.includes('S2D')
    );
    if (actualS2DViewTypes.length === 4 || actualS2DViewTypes.length === 0)
      return actualS2DViewTypes;
    return [
      {
        name: 'RCC_S2D',
        type: 'single',
      },
      {
        name: 'LCC_S2D',
        type: 'single',
      },
      {
        name: 'RMLO_S2D',
        type: 'single',
      },
      {
        name: 'LMLO_S2D',
        type: 'single',
      },
    ];
  },
});

const dbtViewTypesForCurrentScanType = selector({
  key: 'imageState/dbtViewTypesForCurrentScanType',
  get: ({ get }) => {
    const dbtScanType = get(taskState.selectedDBTScanType);
    if (dbtScanType === 'FFDM') return get(ffdmViewTypes);
    if (dbtScanType === '3D') return get(dbt3DViewTypes);
    return get(dbtS2DViewTypes);
  },
});

const hasDBT3DViews = selector({
  key: 'imageState/hasDBT3DViews',
  get: ({ get }) => {
    const dbt3DViewTypesArray = get(dbt3DViewTypes);
    return !!dbt3DViewTypesArray.length;
  },
});

const hasFFDMViews = selector({
  key: 'imageState/hasFFDMViews',
  get: ({ get }) => {
    const ffdmViewTypesArray = get(ffdmViewTypes);
    return !!ffdmViewTypesArray.length;
  },
});

const hasDBTS2DViews = selector({
  key: 'imageState/hasDBTS2DViews',
  get: ({ get }) => {
    const dbtS2DViewTypesArray = get(dbtS2DViewTypes);
    return !!dbtS2DViewTypesArray.length;
  },
});

/**
 * @description: Since DBT's multiple scan types - 3D, FFDM(2D), and S2D show the same object
 * from the same views (LCC, RCC, LMLO, RMLO), findings in one view should be visible
 * in all scan types.
 * For example: An LCC_3D finding should be grouped with an LCC_FFDM finding, and an LCC_S2D finding.
 * If the user tries to save a DBT job with ungrouped findings as described above,
 * the app should show a warning/confirmation. In other modalities, ungrouped findings
 * are OK and the app should not show a warning/confirmation.
 */
const isDBTWithMultipleScanTypes = selector({
  key: 'imageState/isDBTWithMultipleScanTypes',
  get: ({ get }) => {
    // without explicit assertion, TypeScript@4.5.2 could not infer the type of localViewTypes
    const localViewTypes = get(imageState.viewTypes) as ViewNameType[];

    const non3DImages = localViewTypes.some(
      image => !!image.type && image.type === 'single'
    );
    const images3D = localViewTypes.some(
      image => !!image.type && image.type === 'multiple'
    );

    return non3DImages && images3D;
  },
});

const imageState = Object.freeze({
  cornerstoneImage,
  currentDBTFrameNeighborsPreloadingQty,
  nextDBTJobFramesPreloadingQty,
  imagesPreloading,
  viewTypes,
  dbt3DViewTypes,
  ffdmViewTypes,
  dbtS2DViewTypes,
  hasFFDMViews,
  hasDBT3DViews,
  hasDBTS2DViews,
  dbtViewTypesForCurrentScanType,
  currentJobImagesLoading,
  nextJobImagesLoading,
  isDBTWithMultipleScanTypes,
});

export default imageState;
